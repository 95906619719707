// import { auth, provider } from "../config/firebase.ts";
// import { setPersistence, browserLocalPersistence, signInWithEmailAndPassword, signInWithPopup, sendEmailVerification, signOut } from "firebase/auth";
// import { Link, useNavigate } from "react-router-dom";
// import { useState, useEffect } from "react";
// import React from 'react';
// import logo from '../images/AZ LIFE2.png';
// import "./log_email.css";
// import { checkIfUserIsBlocked } from "../admin/blockuser.tsx"; 

// export const LoginWithEmail = () => {
//     const navigate = useNavigate();
//     const [email, setEmail] = useState(localStorage.getItem("email") || ""); // Pre-fill from localStorage
//     // const [password, setPassword] = useState(localStorage.getItem("password") || ""); // Pre-fill from localStorage
//     const [password, setPassword] = useState(""); 
//     const [error, setError] = useState<string | null>(null);
//     const [showPassword, setShowPassword] = useState(false);
//     const [isLoading, setIsLoading] = useState(false); // For loading spinner


//     // Function to handle email and password sign-in
//     const signInWithEmail = async (e) => {
//         e.preventDefault();
//         setIsLoading(true); // Start loading spinner

//         try {
//             localStorage.setItem('email', email); // Save the email to localStorage
//             await setPersistence(auth, browserLocalPersistence); // Ensure Firebase persistence is set to 'local'
//             // await signInWithEmailAndPassword(auth, email, password);
//             const result = await signInWithEmailAndPassword(auth, email, password);
            
//             // navigate('/'); // Redirect to the home page after successful login
//              // Check if the user's email is verified
//              if (result.user.emailVerified) {

//                 const isBlocked = await checkIfUserIsBlocked(result.user.uid);
//                 if (isBlocked) {
//                     setError("你的账号被封了，请联系管理员.");
//                     await signOut(auth); 
//                     return;
//                 }  
//                 navigate('/'); // Redirect to the home page after successful login
//             } else {
//                 // If the email is not verified, show an error message and give option to re-send email
//                 setError("请验证邮箱.");
//             }


        
//         } catch (error) {
//             setError(error.message);
//             // console.error("Email sign-in error:", error);
//             {error && <div className="error-message">{error}</div>}
//         }finally {
//             setIsLoading(false); // Stop loading spinner
//         }
//     };

//     // Function to handle Google sign-in
//     const SignInWithGoogle = async () => {
//         setIsLoading(true); // Start loading spinner for Google sign-in
//         try {
                     
//             const result = await signInWithPopup(auth, provider);
//             const isBlocked = await checkIfUserIsBlocked(result.user.uid);
//             if (isBlocked) {
//                 setError("你的账号被封了，请联系管理员.");
//                 await signOut(auth); 
//                 return;
//             }  
//             // console.log(result);
//             navigate('/'); // Redirect to home after Google login
//         } catch (error) {
//             setError(error.message);
//             console.error('Google sign-in error:', error);
//         }
//         finally {
//             setIsLoading(false); // Stop loading spinner
//         }
//     };



//     // Re-send verification email if the user clicks on a button
//     const resendVerificationEmail = async () => {
//         const user = auth.currentUser;
//         if (user && !user.emailVerified) {
//             try {
//                 await sendEmailVerification(user);
//                 setError("Verification email re-sent. Please check your inbox.");
//             } catch (error) {
//                 setError("Error sending verification email.");
//                 console.error("Verification email error:", error);
//             }
//         }
//     };

//     return (
//         <div className="signin-container">
//             <div className="signin-logo">
//                 <Link to='/'>
//                     <img src={logo} width="150px" alt="AZ LIFE Logo" className="signin-logo-item"/>
//                 </Link>
//             </div>
//             <div className='signin'>
//                 <form onSubmit={signInWithEmail} className="signin-form">
//                     <h2>登录</h2>
//                     {error && <p style={{ color: "red" }}>{error}</p>}
//                     <button type="button" onClick={SignInWithGoogle} className="gmaillogin">Gmail 登录</button>
//                     <div className="divider"><span>OR</span></div>

//                     {/* Email Input */}
//                     <div className="signin_email">Email:</div>
//                     <input
//                         type="email"
//                         value={email} // Pre-fill email from localStorage
//                         onChange={(e) => setEmail(e.target.value)}
//                         required
//                         className="signinput"
//                     />

//                     {/* Password Input */}
//                     <div className="signin_password">
//                         <div>Password:</div>
//                         <Link to='/forgotpassword'>
//                             Forgot password?
//                         </Link>
//                     </div>
//                     <input
//                         type={showPassword ? "text" : "password"}
//                         value={password} // Pre-fill password from localStorage
//                         onChange={(e) => setPassword(e.target.value)}
//                         required
//                         className="signinpassword"
//                     />

//                     {/* Show Password Checkbox */}
//                     <div className='showpassword-bx'>
//                         <input
//                             type="checkbox"
//                             checked={showPassword}
//                             onChange={() => setShowPassword(!showPassword)}
//                             className="showpassword-checkbx"
//                             id="myCheckbox"
//                         />
//                         <label className="showpassword-label" htmlFor="myCheckbox">Show Password</label>
//                     </div>

//                     {/* Submit Button */}
//                     {/* <div>
//                         <button type="submit" className="submit">Sign In</button>
//                     </div> */}
//                       {/* Submit Button */}
//                       <div>
//                         <button type="submit" className="submit" disabled={isLoading}>
//                             {isLoading ? "Signing In..." : "Sign In"}
//                         </button>
//                     </div>

//                     {/* Re-send verification email if needed */}
//                     {error === "Please verify your email before logging in." && (
//                         <div >
//                             <button className="resend-verification" type="button" onClick={resendVerificationEmail} disabled={isLoading}>
//                                 Re-send verification email
//                             </button>
//                         </div>
//                     )}
//                 </form>

//                 {/* Link to Sign-Up Page */}
//                 <div className="signup">
//                     <div>新用户？</div>
//                     <Link to='/signup'>
//                         注册新账户
//                     </Link>
//                 </div>
//             </div>
//         </div>
//     );
// };

import React, { useState } from "react";
import { auth, provider } from "../config/firebase.ts";
import { setPersistence, browserLocalPersistence, signInWithEmailAndPassword, signInWithPopup, sendEmailVerification, signOut } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import logo from '../images/AZ LIFE2.png';
import "./log_email.css";
import { checkIfUserIsBlocked } from "../admin/blockuser.tsx"; 
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../config/firebase.ts";

export const LoginWithEmail = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState(localStorage.getItem("email") || ""); // Pre-fill from localStorage
    const [password, setPassword] = useState(""); 
    const [error, setError] = useState<string | null>(null);
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // For loading spinner

    // Function to handle email and password sign-in
    const signInWithEmail = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true); // Start loading spinner

        try {
            localStorage.setItem('email', email); // Save the email to localStorage
            await setPersistence(auth, browserLocalPersistence); // Ensure Firebase persistence is set to 'local'
            const result = await signInWithEmailAndPassword(auth, email, password);
            
            // Check if the user's email is verified
            if (result.user.emailVerified) {
                const isBlocked = await checkIfUserIsBlocked(result.user.uid);
                if (isBlocked) {
                    setError("你的账号被封了，请联系管理员.");
                    await signOut(auth); 
                    return;
                }  

                // Check if user exists in Firestore database
                const userDoc = await getDoc(doc(db, "users", result.user.uid));
                if (!userDoc.exists()) {
                    setError("User not found in the database.");
                    await signOut(auth);
                    return;
                }

                navigate('/'); // Redirect to the home page after successful login
            } else {
                // If the email is not verified, show an error message and give option to re-send email
                setError("请验证邮箱（可能在垃圾邮件里）.");
            }

        } catch (error: any) {
            handleAuthError(error);
        } finally {
            setIsLoading(false); // Stop loading spinner
        }
    };

    // Function to handle Google sign-in
    const SignInWithGoogle = async () => {
        setIsLoading(true); // Start loading spinner for Google sign-in
        try {
            const result = await signInWithPopup(auth, provider);

            const isBlocked = await checkIfUserIsBlocked(result.user.uid);
            if (isBlocked) {
                setError("你的账号被封了，请联系管理员.");
                await signOut(auth); 
                return;
            }

             // Check if user exists in Firestore, if not, create the user document
             const userDocRef = doc(db, "users", result.user.uid);

            // Check if user exists in Firestore database
            const userDoc = await getDoc(doc(db, "users", result.user.uid));
            // if (!userDoc.exists()) {
            //     setError("User not found in the database.");
            //     await signOut(auth);
            //     return;
            // }

            if (!userDoc.exists()) {
                // If user doesn't exist, create a new document
                await setDoc(userDocRef, {
                    uid: result.user.uid,
                    email: result.user.email,
                    displayName: result.user.displayName || result.user.email,
                    createdAt: new Date(),
                });
            }

            navigate('/'); // Redirect to home after Google login
        } catch (error: any) {
            handleAuthError(error);
        } finally {
            setIsLoading(false); // Stop loading spinner
        }
    };

    // Re-send verification email if the user clicks on a button
    const resendVerificationEmail = async () => {
        const user = auth.currentUser;
        if (user && !user.emailVerified) {
            try {
                await sendEmailVerification(user);
                setError("Verification email re-sent. Please check your inbox.");
            } catch (error) {
                setError("Error sending verification email.");
                console.error("Verification email error:", error);
            }
        }
    };

    // Centralized function to handle Firebase Auth errors
    const handleAuthError = (error: any) => {
        if (error.code === 'auth/email-already-in-use') {
            setError('This email is already in use. Please try signing in.');
        } else if (error.code === 'auth/user-disabled') {
            setError('This account has been disabled.');
        } else if (error.code === 'auth/user-not-found') {
            setError('User not found. Please check your email or sign up.');
        } else if (error.code === 'auth/wrong-password') {
            setError('Incorrect password. Please try again.');
        } else {
            setError(error.message);
        }
    };

    return (
        <div className="signin-container">
            <div className="signin-logo">
                <Link to='/'>
                    <img src={logo} width="150px" alt="AZ LIFE Logo" className="signin-logo-item" />
                </Link>
            </div>
            <div className='signin'>
                <form onSubmit={signInWithEmail} className="signin-form">
                    <h2>登录</h2>
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    <button type="button" onClick={SignInWithGoogle} className="gmaillogin">Gmail 登录</button>
                    <div className="divider"><span>OR</span></div>

                    {/* Email Input */}
                    <div className="signin_email">Email:</div>
                    <input
                        type="email"
                        value={email} // Pre-fill email from localStorage
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="signinput"
                    />

                    {/* Password Input */}
                    <div className="signin_password">
                        <div>Password:</div>
                        <Link to='/forgotpassword'>Forgot password?</Link>
                    </div>
                    <input
                        type={showPassword ? "text" : "password"}
                        value={password} // Pre-fill password from localStorage
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="signinpassword"
                    />

                    {/* Show Password Checkbox */}
                    <div className='showpassword-bx'>
                        <input
                            type="checkbox"
                            checked={showPassword}
                            onChange={() => setShowPassword(!showPassword)}
                            className="showpassword-checkbx"
                            id="myCheckbox"
                        />
                        <label className="showpassword-label" htmlFor="myCheckbox">Show Password</label>
                    </div>

                    {/* Submit Button */}
                    <div>
                        <button type="submit" className="submit" disabled={isLoading}>
                            {isLoading ? "Signing In..." : "Sign In"}
                        </button>
                    </div>

                    {/* Re-send verification email if needed */}
                    {error === "请验证邮箱." && (
                        <div>
                            <button className="resend-verification" type="button" onClick={resendVerificationEmail} disabled={isLoading}>
                                重新发送验证邮件
                            </button>
                        </div>
                    )}
                </form>

                {/* Link to Sign-Up Page */}
                <div className="signup">
                    <div>新用户？</div>
                    <Link to='/signup'>注册新账户</Link>
                </div>
            </div>
        </div>
    );
};
