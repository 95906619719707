import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../config/firebase.ts";
import { CategoryMap, findKeyByCategoryName } from "../pages/categorymap.tsx";

interface Post {
    id: string;
    title: string;
    category: string; // Make sure the category is part of your Post interface
    createdAt: { seconds: number; nanoseconds: number }; 
    icon?: string; // Optional, default to a specific icon if undefined
    rank?: number; // Optional, default to 0 if not provided
    deleted?: boolean;
}

interface ClusterProps {
    section_name: string;
    link: string;
    posts: Post[];
    isAdmin: boolean

}

const Cluster: React.FC<ClusterProps> = ({ section_name, link, posts, isAdmin}) => {

    const expectedCategory = findKeyByCategoryName(section_name); // Get the expected category from the mapping
    // console.log('expectedCategory: ', expectedCategory)


    const latestPosts = posts
        .filter(post => post.category === expectedCategory && (post.deleted !== true)) // Filter by category
        .sort((a, b) => {
            // First, compare by rank: if ranks are the same (both 0), compare by createdAt
            if ((b.rank || 0) !== (a.rank || 0)) {
                return (b.rank || 0) - (a.rank || 0);  // Higher rank first
            }
            // If both ranks are equal (e.g., both are 0), sort by createdAt
            return b.createdAt.seconds - a.createdAt.seconds;  // Newer posts first
        })
        .slice(0, 10); // Get the latest 10 posts

    const rankPostToFirst = async (postId: string) => {
        try {
            // Get all posts and find the highest rank
            const postsSnapshot = await getDocs(collection(db, "posts"));
            const allPosts = postsSnapshot.docs.map(doc => doc.data() as Post);
            const highestRank = Math.max(...allPosts.map(post => post.rank || 0));

            // Set this post's rank to be higher than the current highest
            const newRank = highestRank + 1;

            // Update the post in the database
            const postDoc = doc(db, "posts", postId);
            await updateDoc(postDoc, { rank: newRank });

            alert("Post has been promoted to first!");
        } catch (error) {
            console.error("Error ranking post to first:", error);
            alert("An error occurred while promoting the post.");
        }
    };
    
    
    
    
        return (
        <div className="cluster">
            <div className="cluseter_wrap">
                <div className="cluseter_container">
                    <h4 className="section_name">
                        <Link to={link} >{section_name}</Link>
                    </h4>

                    <div className="posts-container"> 
                        <div className="latest-posts">
                        {latestPosts.length > 0 ? (
                            latestPosts.map(post => (
                                <div key={post.id} className="post">
                                    <Link to={`/category/${post.category}/${post.id}`} className="post-link">
                                        {`${CategoryMap[post.category][1]} ${post.title}`}  {/* Show different icon based on category */}
                                    </Link>

                                    {/* Admin button to rank post first */}
                                    {isAdmin && (
                                            <button onClick={() => rankPostToFirst(post.id)}>
                                                Rank First
                                            </button>
                                    )}



                                </div> 
                            ))
                        ) : (
                            <p className="no-homepage-post">没有帖子可显示</p>  
                        )}
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
};

export default Cluster;
