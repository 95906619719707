import React from "react"
import "./readme.css"
import home_screen from "../images/home-screen-set-up.png"
import { Helmet } from "react-helmet"; // Import Helmet
import azLifeLogo from "../images/AZ LIFE2.png";

export const ReadMe = () => {

    return (

        <div className="readme-wrapper">
              <Helmet>
                <title>用户须知 | AZ Life</title>
                <meta name="description" content="在这里查看AZ Life用户须知，包括发帖规则、帮助信息、广告合作等重要信息。" />
                <meta property="og:title" content="用户须知 | AZ Life" />
                <meta property="og:description" content="请查看AZ Life用户须知，了解如何发帖、评论、广告合作等。" />
                {/* <meta property="og:image" content={azLifeLogo} />  */}
                <meta property="og:url" content="https://azlife168.com/readme" />
                <meta property="og:type" content="website" />
            </Helmet>
                <div className="readme">
                    <h3>用户须知</h3>
                        <ol>
                            <li>
                                请文明发帖和评论。
                            </li>
                            <li>
                                如需帮助请联系管理员，联系方式见主页底部。
                            </li>
                            <li>
                                买卖和租房帖子两周后自动删除。
                            </li>
                            <li>
                                商业广告需要和管理员联系，洽谈合作事宜。
                            </li>
                            <li>
                               欢迎将帖子分享至朋友圈或微信群。
                            </li>
                            <li>
                               误删帖子可以联系管理员恢复。
                            </li>
                            {/* <li>
                               在手机上可将网页添加到Home Screen，和app一样丝滑。
                            </li> */}
                            
                        </ol>
                        {/* <img src={home_screen} className="home-screen" /> */}
                </div>
        </div>




    )
}