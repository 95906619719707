import React, { useEffect, useState } from "react";
import { collection, doc, getDocs, updateDoc, query, where, deleteDoc, addDoc } from "firebase/firestore";
import { db } from "../config/firebase.ts"; // Adjust the import based on your structure
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import "./admin-dashboard.css"
import logo from '../images/AZ LIFE2.png'; 
import { Link } from "react-router-dom";
import { EditablePost } from "./editable-post.tsx";
import { ReviewPosts } from "./review_posts.tsx";
import search_icon from '../images/search.svg';


interface Comment {
  id: string;
  parentId?: string;  // Optional parentId field
  comment: string;
  createdAt: { seconds: number, nanoseconds: number };
  userId: string;
}


  


export const AdminDashboard = () => {

  const [username, setUserName] = useState("");
  const [userInfo, setUserInfo] = useState<any>(null); // Store user information
  const [userPosts, setUserPosts] = useState<any[]>([]); // Store user's posts
  const [userComments, setUserComments] = useState<any[]>([]);
  const [userReplies, setUserReplies] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [unreviewedPost, setUnreviewedPost] = useState<number|null>(null)
  const [deletedPost, setDeletedPost] = useState<number|null>(null)
   const [message, setMessage] = useState<string | null>(null); // Success message state


  
  // Function to find a user by their username
   const findUserByUsername = async (username: string) => {
    setMessage(null);
    setError(null);

    const usersRef = collection(db, "users"); // Assuming you have a "users" collection

    
    const q = query(usersRef, where("username", "==", username)); // Query Firestore to find the user by username
  
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      console.log(`User ID: ${doc.id}, User Data:`, doc.data());
      
    });
 
        try {if (!querySnapshot.empty) {
            // If a user is found, return the user data (including their UID)
            const userDoc = querySnapshot.docs[0]; // Assuming usernames are unique
            // return userDoc.data(); 
            const userData = userDoc.data();
            // console.log('Userdata: ', userData.username)
            setUserInfo({...userData, userId: userDoc.id}); // Set user information to state
            console.log('userInfo', userInfo)

          } else {
            username && setError(`${username} not found.`);
            setUserInfo(null); 
          }
        } catch (error) {
            setError("Error fetching user data"); // Handle other potential errors
            setUserInfo(null); // Clear user info on error
        };
}


    const fetchUnreviewedPostsNumber = async() => {

      const unreviewedPostRef = collection(db, 'posts');
      const q = query(unreviewedPostRef, where("approved", "==", false), where("rejected", "==", false));
      try {
        
        const querySnapshot = await getDocs(q);
        setUnreviewedPost(querySnapshot.size);
        console.log('unreviewedposts', querySnapshot.size)
    } catch (error) {
      console.error('Error fetching the unreviewed posts number: ,', error)
    }
  }

  const fetchDeletedPostsNumber = async() => {

    const udeletedPostRef = collection(db, 'posts');
    const q = query(udeletedPostRef, where("deleted", "==", true));
    try {
      
      const querySnapshot = await getDocs(q);
      setDeletedPost(querySnapshot.size);
      console.log('deletedposts', querySnapshot.size)
  } catch (error) {
    console.error('Error fetching the deleted posts number: ,', error)
  }
}



    // Fetch unreviewed post count when component mounts
    useEffect(() => {
      fetchUnreviewedPostsNumber();
      fetchDeletedPostsNumber();
    }, []); // Empty array ensures it runs only once when the component mounts


   // Function to fetch user's posts
   const fetchUserPosts = async (userId: string) => {
    const postsRef = collection(db, "posts"); // Assuming you have a "posts" collection
    const q = query(postsRef, where("userId", "==", userId)); // Query Firestore for posts by userId
    const querySnapshot = await getDocs(q);

    const posts = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setUserPosts(posts); // Store posts in state
  };




   // Function to fetch user's posts
   const fetchComments = async (userId: string) => {
    const postsRef = collection(db, "comments"); // Assuming you have a "posts" collection
    const q = query(postsRef, where("userId", "==", userId)); // Query Firestore for posts by userId
    const querySnapshot = await getDocs(q);
    const comments: Comment[] = querySnapshot.docs.map((doc) =>({
      id: doc.id,
      ...doc.data(),
    })) as Comment[];

    const userComments = comments.filter((comment:Comment)=>!comment.parentId);
    setUserComments(userComments);

    const userReplies = comments.filter((comment:Comment)=>comment.parentId);
    setUserReplies(userReplies);
  };

  useEffect(()=> {
    if(userInfo?.userId) {
      fetchUserPosts(userInfo.userId);
    }
  }, [userInfo])


  useEffect(()=> {
    if(userInfo?.userId) {
      fetchComments(userInfo.userId);
    }
  }, [userInfo])


// Function to block a user by their username and add their details to the blockedUsers collection
  const blockUserByUsername = async (username: string, reason: string) => {
    const usersRef = collection(db, 'users'); // Collection reference to 'users'
    const q = query(usersRef, where('username', '==', username)); // Query to search user by username
 

    try {
      const querySnapshot = await getDocs(q);
      
  
      if (!querySnapshot.empty) {
        // Assuming the username is unique, we grab the first matched document
        const userDoc = querySnapshot.docs[0];
        const userData = userDoc.data();


        const userDocRef = doc(db, 'users', userDoc.id);
        await updateDoc(userDocRef, {
            blocked: true, 
            reason: reason,
          });
  
        // Add the user to the blockedUsers collection without changing their status in users collection
        const blockedUserRef = collection(db, 'blockedUsers'); // Reference to 'blockedUsers' collection
        await addDoc(blockedUserRef, {
          userId: userDoc.id,
          username: userData.username,
          email: userData.email,
          accountCreated: userData.accountCreated || new Date(), // Use account creation time if available
          reason: reason, // Include the reason for blocking
          blockedAt: new Date() // The current timestamp when the user was blocked
        });
  
        console.log(`User ${username} has been blocked and added to blockedUsers collection.`);
      } else {
        console.log(`No user found with that ${username}.`);
      }
    } catch (error) {
      console.error("Error blocking the user by username:", error);
      // throw error; 
    }
  };

  
  // Function to unblock a user by deleting their document from the blockedUsers collection
const unblockUserByUsername = async (username: string) => {
  const blockedUsersRef = collection(db, 'blockedUsers'); // Reference to 'blockedUsers' collection
  const q = query(blockedUsersRef, where('username', '==', username)); // Query by username in blockedUsers collection
  
  
 
  try {
    const querySnapshot = await getDocs(q);

    console.log('dgee', querySnapshot);
    

    if (!querySnapshot.empty) {
      // Assuming the username is unique, we grab the first matched document
      const blockedUserDoc = querySnapshot.docs[0];
      const blockedUserDocRef = doc(db, 'blockedUsers', blockedUserDoc.id);

      
      
      const usersRef = collection(db, 'users'); // Collection reference to 'users'
      const user_q = query(usersRef, where('username', '==', username)) ;
      const user_snapshot = await getDocs(user_q);
      const userDocs = user_snapshot.docs[0];
      const userDocRef = doc(db, 'users', userDocs.id)
        await updateDoc(userDocRef, {
          blocked: false,
        });

      // Delete the user from the blockedUsers collection to unblock them
      await deleteDoc(blockedUserDocRef);

      setMessage(`User ${username} has been unblocked and removed from blockedUsers collection.`);
    } else {
      setMessage(`No user found with that ${username} in blockedUsers.`);
    }
  } catch (error) {
    console.error("Error unblocking the user by username:", error);
    throw error;
  }
};


  // Handle blocking the user
  const handleBlockUser = async () => {
    
     // Reset messages and errors before processing
      setMessage(null);
      setError(null);

    
    if (username) {
      try {
        // Query the Firestore 'users' collection to check if the username exists
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('username', '==', username));
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          // User exists, now prompt for the reason
          const reason = prompt("Please enter the reason for blocking the user:");
          if (!reason) {
            setError("A reason is required to block the user.");
            return;
          }
  
          // Call the blockUserByUsername function with the reason
          await blockUserByUsername(username, reason);
          setMessage(`User ${username} has been blocked for: ${reason}`);
          setError(null);
        } else {
          // No user found with that username
          setMessage(`No user found with that ${username}`);
        }
      } catch (error) {
        console.error("Error fetching user:", error);
        setError("An error occurred while checking the username.");
      }
    } else {
      setError("Please enter a username.");
    }


  };
  // Handle unblocking the user
  const handleUnblockUser = async () => {
    if (username) {
      try {
        await unblockUserByUsername(username);
        // setMessage(`User ${username} has been unblocked.`);
        setError(null);
      } catch (error) {
        setError("Error unblocking the user.");
      }
    } else {
      setError("Please enter a username.");
    }
  };

    // Event handler for key down event
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        findUserByUsername(username); // Trigger the search when Enter is pressed
      }
    }

    
 

  return(

   
    <div className="wrapper">
       <Link to="/" >
                <img src={logo} width="150px" alt="Logo" className="admin-logo" />
        </Link>
      <div className="admin-container">
     
            <div className="search-bar">
                  <input 
                      type="text"
                      value={username}
                      onChange={(e) => setUserName(e.target.value)}
                      placeholder="username"
                      className="username-input"
                      onKeyDown={handleKeyDown} 
                  />
                  <button className="search-btn" onClick={()=>findUserByUsername(username)}>
                      <img src={search_icon} alt="search-icon" className="serch-icon"/>
                  </button>

            </div>

            <div className="block-btns">
                <button  onClick={handleBlockUser} className="block-btn">Blocked</button>
                <button onClick={handleUnblockUser} className="block-btn">Unblocked</button>

                <Link to='/postreviews'>
                      <button className="review-post-button">
                        Post review (<span style={{color: "green", fontWeight: "bold"}}>{unreviewedPost}</span>)
                      </button>
                </Link>

                <Link to='/deletedposts'>
                      <button className="deleted-post-button">
                        Deleted posts (<span style={{color: "green", fontWeight: "bold"}}>{deletedPost}</span>)
                      </button>
                </Link>
           
           
           
            </div>

                {/* Message and Error display */}
                {message && <p style={{ color: "green" }}>{message}</p>}
              
                {/* {error && <p style={{ color: "red" }}>{error}</p>} */}

          

            <div className="user-info-print">
                {username ? (userInfo ? (
                  <>
                  <div className="basic-info">
                    <h3 className="title-info">Here is the information of {userInfo.username}:</h3>
                
                    <ol>
                        <li>Username: {userInfo.username}</li>
                        <li>Email: {userInfo.email}</li>
                        <li>Created date: {userInfo.createdAt ? (
                            new Date(userInfo.createdAt.seconds * 1000).toLocaleDateString()
                          ) : ("No creation date available") }
                        </li>
                       
                    </ol>
                   
                  </div>



                  <div className="blocked-history">
                    <h3 className="title-info">Here is blocked records of {userInfo.username}:</h3>
                    <ul>
                      <li>
                    {userInfo.blocked === undefined ? (
                            <p><ul><li>No block history</li></ul></p> // If "blocked" field doesn't exist
                          ) : userInfo.blocked ? (
                            <p>{userInfo.username} is currently blocked for {userInfo.reason}.</p> // If "blocked" is true
                          ) : (
                            <p>{userInfo.username} was blocked for for {userInfo.reason} but is now unblocked.</p> // If "blocked" is false
                          )}
                          </li>
                   </ul> 
                  </div>



                  <div className="posts-info">
                          <h3 className="title-info">Posts by {userInfo.username}:</h3>   
                              
                          {userPosts && userPosts.length > 0 ? ( 
                              
                              
                              <ul>
                                  {userPosts.map((post) => (
                                    <Link to={`/category/${post.category}/${post.id}`}>
                                    <li key={post.id} className="admin-post-link">
                                      {post.title} - {new Date(post.createdAt.seconds * 1000).toLocaleDateString()}
                                    </li>
                                    </Link>
                                  ))}
                              </ul> 
                              ):

                                    <div><ul><li>No posts found</li></ul></div>
                                  
                              }
                  </div>


                  <div className="comments-info">
                      <h3 className="title-info">Comments by {userInfo.username}:</h3>

                      {userComments && userComments.length > 0 ? ( 
                      <ul>
                        {userComments.map((comment) => (
                          <li key={comment.id}>
                            {comment.comment} - {new Date(comment.createdAt.seconds * 1000).toLocaleDateString()}
                          </li>
                        ))}
                      </ul>
                      ):
                      <div><ul><li>No comments found</li></ul></div>
                    }




                    <div className="replies-info">  

                      <h3 className="title-info"> Replies by {userInfo.username}:</h3>

                      
                      {userReplies && userReplies.length > 0 ? (
                          <ul>
                            {userReplies.map((reply) => (
                              <li key={reply.id}>
                                {reply.comment} (Reply) - {new Date(reply.createdAt.seconds * 1000).toLocaleDateString()}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <div className="replies-bot"><ul><li>No replies found</li></ul></div>
                        )}
                        
                      </div>

                    </div>
                  </>
                )  : ( error && <p>{error}</p>

                ) 
              ) : (
                <p>Please input a username</p>
                  
                )}
              </div>
            </div>
      </div>
  
  );
};
