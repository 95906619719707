import React, { useEffect, useState } from "react";
import { collection, doc, getDocs, updateDoc, query, where, deleteDoc } from "firebase/firestore";
import { db } from "../config/firebase.ts"; // Adjust the import based on your structure
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

import logo from '../images/AZ LIFE2.png'; 
import { Link } from "react-router-dom";
import { EditablePost } from "./editable-post.tsx";
import "./review_posts.css"

import { getStorage, ref, deleteObject } from "firebase/storage"; // Import these from firebase/storage




interface Post {
    imageUrls: string[];
    username: string;
    id: string;
    title: string;
    description: string;
    category: string;
    approved: boolean;
    rejected: boolean;  // Add a field to identify rejected posts
    userId: string; // User ID of the author for notifications
    rejectionReason?: string; // Add rejection reason if available
  }
  


export const ReviewPosts = () => {
  const [user, loading] = useAuthState(getAuth());
  const [isAdmin, setIsAdmin] = useState(false);
  const [posts, setPosts] = useState<Post[]>([]);
  const [rejectedPosts, setRejectedPosts] = useState<Post[]>([]); // State for rejected posts

  useEffect(() => {
    if (loading) return; // Don't proceed if still loading

    const checkAdminStatus = async () => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        if (idTokenResult.claims.admin) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      }
    };

    checkAdminStatus();
  }, [user, loading]);



//   useEffect(() => {
//     const fetchUnapprovedPosts = async () => {
//       try {
//         const postsQuery = query(collection(db, "posts"), where("approved", "==", false));
//         const snapshot = await getDocs(postsQuery);
//         const fetchedPosts = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Post));
//         setPosts(fetchedPosts);
//       } catch (error) {
//         console.error("Error fetching posts:", error);
//       }
//     };

//     if (isAdmin) {
//       fetchUnapprovedPosts();
//     }
//   }, [isAdmin]);

// console.log('EditablePosts: ', EditablePost);

const fetchUnapprovedAndRejectedPosts = async () => {
    try {
      const postsQuery = query(collection(db, "posts"), where("approved", "==", false),
      where("rejected", "==", false));
      const rejectedQuery = query(collection(db, "posts"), where("rejected", "==", true)); 


      const [unapprovedSnapshot, rejectedSnapshot] = await Promise.all([
        getDocs(postsQuery),
        getDocs(rejectedQuery),
      ]);

    const fetchedUnapprovedPosts: Post[] = unapprovedSnapshot.docs.map(doc => {
    const data = doc.data();
    return {
      id: doc.id,
      imageUrls: data.imageUrls || [],
      username: data.username || 'Unknown',
      title: data.title || 'No Title',
      description: data.description || 'No Description',
      category: data.category || 'Uncategorized',
      approved: data.approved || false,
      rejected: data.rejected || false,  // Check if rejected
      userId: data.userId || 'Unknown',
    };
  });

const fetchedRejectedPosts: Post[] = rejectedSnapshot.docs.map(doc => {
    const data = doc.data();
    return {
      id: doc.id,
      imageUrls: data.imageUrls || [],
      username: data.username || 'Unknown',
      title: data.title || 'No Title',
      description: data.description || 'No Description',
      category: data.category || 'Uncategorized',
      approved: data.approved || false,
      rejected: data.rejected || true,
      rejectionReason: data.rejectionReason || 'No reason provided', // Handle rejection reason
      userId: data.userId || 'Unknown',
    };
  });

  setPosts(fetchedUnapprovedPosts); // Set unapproved posts
  setRejectedPosts(fetchedRejectedPosts); // Set rejected posts
} catch (error) {
  console.error("Error fetching posts:", error);
}
};

// Call fetchUnapprovedAndRejectedPosts when the component mounts
useEffect(() => {
fetchUnapprovedAndRejectedPosts();
}, []);




  const approvePost = async (postId: string) => {
    const postRef = doc(db, "posts", postId);
    try {
      await updateDoc(postRef, {
        approved: true,
        rejected: false,  // Ensure it's no longer rejected
      });
      setPosts(posts.filter(post => post.id !== postId)); // Remove the approved post from the list
      console.log("Post approved successfully");
      fetchUnapprovedAndRejectedPosts(); // Refresh both sections
    } catch (error) {
      console.error("Error approving post: ", error);
    }
  };



 // Function to reject the post with a reason
 const rejectPost = async (postId: string) => {
    const postRef = doc(db, "posts", postId);
    try {
      await updateDoc(postRef, {
        approved: false,
        rejected: true,
      });

      setPosts(posts.filter(post => post.id !== postId)); // Remove the approved post from the list
      console.log("Post approved successfully");
      fetchUnapprovedAndRejectedPosts(); // Refresh both sections

   
    } catch (error) {
      console.error("Error rejecting post: ", error);
    }
  };



  if (!isAdmin) {
    return <p>Only admins can access this page.</p>;
  }

    // Function to delete a rejected post
    const deletePost = async (postId: string, imageUrls: string[]) => {
        const postRef = doc(db, "posts", postId);
        const confirmDeletion = window.confirm("Are you sure you want to delete this post? This action cannot be undone.");
    
        if (confirmDeletion) {
          try {

            // Delete each image associated with the post
              const storage = getStorage();
              const deleteImagePromises = imageUrls.map((url) => {
                const imageRef = ref(storage, url);
                return deleteObject(imageRef).catch((error) => {
                  console.error("Error deleting image:", error);
                });
              });
              await Promise.all(deleteImagePromises); // Wait for all images to be deleted



            await deleteDoc(postRef);
            console.log('Before deleting:', rejectedPosts);
            setRejectedPosts(rejectedPosts.filter((post) => post.id !== postId)); // Remove post from the list after deletion
            console.log('After deleting:', rejectedPosts);
            console.log("Post deleted successfully");
          } catch (error) {
            console.error("Error deleting post: ", error);
          }
        }
      };

  return (

    <div className="admin-dashboard-containers">
        <Link to="/" >
                <img src={logo} width="150px" alt="Logo" className="admin-logo" />
        </Link>

        <h1 className="title-1">Admin Dashboard</h1>
        
        <h2 className="title-2">Unapproved Posts</h2> 
              {posts.length > 0 && (
                      posts.map(post => (
                      <EditablePost key={post.id} post={post} onUpdate={fetchUnapprovedAndRejectedPosts} />
                      ))
                  // ) : (
                  //     <p className="approval-finished">No posts awaiting approval.</p>
                  )}

     
     

            {/* Rejected Posts Section */}
            <h2 className="title-2">Rejected Posts</h2>
            {/* <div> */}
                {rejectedPosts.length > 0 && (
                    rejectedPosts.map(post => (
                    <div key={post.id} className="individual-post">
                        <h2>{post.title}</h2>
                        <p>Category: {post.category}</p>
                        <p>Author: {post.username}</p>
                        <p>{post.description}</p>
                        <p>Rejection Reason: {post.rejectionReason}</p>
                        {Array.isArray(post.imageUrls) && post.imageUrls.length > 0 && (
                        <div className="post-images">
                            {post.imageUrls.map((url, index) => (
                            <img key={index} src={url} alt={`Post Image ${index}`} style={{ width: "200px", margin: "5px" }} />
                            ))}
                        </div>
                        )}
                        {/* Optionally provide an option to re-approve the post */}

                      
                        <button onClick={() => approvePost(post.id)} className="reapprove-btn">Re-Approve</button>
                        {/* Delete button */}
                        <button onClick={() => deletePost(post.id, post.imageUrls)} className="delete-btn">Delete</button>
          
            
                    </div>
                    ))
                        // ) : (
                    // <p className="approval-finished">No rejected posts.</p>
                )}
              {/* </div>    */}
        {/* </div> */}
  </div>
  
  );
};

