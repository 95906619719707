import React, { useEffect, useState } from "react";
import { Navibar } from "./Navibar.tsx";
import "../App.css";
import Cluster from "./Cluster.tsx";
import Footer from "./footer.tsx";
import { collection, query, where, orderBy, limit, onSnapshot } from "firebase/firestore";
import { auth, db } from "../config/firebase.ts";
import { Timestamp } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

// Define the Post interface
interface Post {
    id: string;
    title: string;
    description: string;
    category: string;
    username?: string; // Optional
    imageUrls: string[]; // Ensure this is an array
    createdAt: Timestamp;
}

export const Home = () => {
    const [hotpostsPosts, setHotpostsPosts] = useState<Post[]>([]);
    const [marketingPosts, setMarketingPosts] = useState<Post[]>([]);
    const [sellPosts, setSellPosts] = useState<Post[]>([]);
    const [buyPosts, setBuyPosts] = useState<Post[]>([]);
    const [forRentPosts, setForRentPosts] = useState<Post[]>([]);
    const [toRentPosts, setToRentPosts] = useState<Post[]>([]);
    const [complainPosts, setComplainPosts] = useState<Post[]>([]);
    const [othersPosts, setOthersPosts] = useState<Post[]>([]);
    const [sharePosts, setSharePosts] = useState<Post[]>([]);
    const [isAdmin, setIsAdmin] = useState(false); // Admin status


    const [user] = useAuthState(auth);
    
    useEffect(() => {
        const checkAdmin = async () => {
          if (user) {
            const idTokenResult = await user.getIdTokenResult();
            if (idTokenResult.claims.admin) {
              setIsAdmin(true);
            }
          }
        };
        checkAdmin();
      }, [user]);
        // Helper function to fetch and store posts by category
    const fetchCategoryPosts = (category: string, setState: React.Dispatch<React.SetStateAction<Post[]>>) => {
        const postsRef = collection(db, "posts");
        const postsQuery = query(
            postsRef,
            where("category", "==", category),  // Query posts by category
            where("approved", "==", true),      // Only get approved posts
            orderBy("createdAt", "desc"),       // Order by createdAt in descending order                         // Limit to 10 posts per query
        );

        // Use onSnapshot to listen for real-time updates
        onSnapshot(postsQuery, (snapshot) => {
            const postsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Post));
            setState(postsData);  // Update the state with the fetched posts
            // console.log(`${category} posts:`, postsData);
        }, (error) => {
            console.error(`Error fetching posts for ${category}: `, error);
        });
    };

    useEffect(() => {
        // Fetch and store posts for each category in separate state variables
        fetchCategoryPosts('hotposts', setHotpostsPosts);
        fetchCategoryPosts('marketing', setMarketingPosts);
        fetchCategoryPosts('share', setSharePosts);
        fetchCategoryPosts('sell', setSellPosts);
        fetchCategoryPosts('buy', setBuyPosts);
        fetchCategoryPosts('for-rent', setForRentPosts);
        fetchCategoryPosts('to-rent', setToRentPosts);
        fetchCategoryPosts('complain', setComplainPosts);
        fetchCategoryPosts('others', setOthersPosts);
    }, []);

  

    return (
        <div className="Home"> 
            {/* <div className="page-header">   */}
                    <Navibar />
            {/* </div>  */}
            <div className="container">
                <div className='clusters'>
                {/* <   Cluster section_name="精华帖" link="/category/hotposts" posts={hotpostsPosts} isAdmin={isAdmin} /> */}
                    {/* <Cluster section_name="商业推广" link="/category/marketing" posts={marketingPosts} isAdmin={isAdmin} /> */}
                    <Cluster section_name="我要分享" link="/category/share" posts={sharePosts} isAdmin={isAdmin} />
                    <Cluster section_name="出售" link="/category/sell" posts={sellPosts}  isAdmin={isAdmin}/>
                    {/* <Cluster section_name="求购" link="/category/buy" posts={buyPosts}  isAdmin={isAdmin}/> */}
                    <Cluster section_name="出租/招租" link="/category/for-rent" posts={forRentPosts} isAdmin={isAdmin}/>
                    {/* <Cluster section_name="求租" link="/category/to-rent" posts={toRentPosts} isAdmin={isAdmin}/> */}
                    {/* <Cluster section_name="我要吐槽" link="/category/complain" posts={complainPosts} isAdmin={isAdmin}/> */}
                    <Cluster section_name="其他" link="/category/others" posts={othersPosts} isAdmin={isAdmin}/>
                  
                </div>
            </div>
            <Footer />
        </div>
    );
};

