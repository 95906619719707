import { collection, query, where, onSnapshot, DocumentData, deleteDoc, doc, addDoc, Timestamp, getDoc } from "firebase/firestore";
import { useEffect, useState, useRef } from "react";
import { auth, db } from "../config/firebase.ts";
import React from "react";
import { FaRegTrashAlt } from 'react-icons/fa';
import "./comments_form.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { useBlockedUser } from "../admin/blockedusercontext.tsx";


export const CommentsDisplay = ({ postId, category, postAuthorId }) => {
  const [comments, setComments] = useState<DocumentData[]>([]);
  const [replyingComment, setReplyingComment] = useState(null);
  const [replyInput, setReplyInput] = useState("");
  const [user, loading] = useAuthState(auth);
  const [isAdmin, setIsAdmin] = useState(false);
  const { isBlocked } = useBlockedUser();



  // Ref to track the reply form (typed as HTMLFormElement or null)
  const replyFormRef = useRef<HTMLFormElement | null>(null);

  // Function to handle clicks outside of the reply form
  const handleClickOutside = (event) => {
    if (replyFormRef.current && !replyFormRef.current.contains(event.target as Node)) {
      setReplyingComment(null); // Hide reply form when clicking outside
    }
  };

  useEffect(() => {
    // Add event listener for detecting clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  // Check if the user is admin based on custom claims
  useEffect(() => {
    const checkAdmin = async () => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        if (idTokenResult.claims.admin) {
          setIsAdmin(true);
        }
      }
    };
    checkAdmin();
  }, [user]);

   

  useEffect(() => {
    const q = query(
      collection(db, "comments"),
      where("postId", "==", postId),
      where("category", "==", category)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      setComments(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    });

    return () => unsubscribe();
  }, [postId, category]);

  comments.map((comment)=> {
    // console.log('comment-userid', comment.id)
    // console.log('equal_userid',user?.uid === comment.id)

   
 
  })

  const handleDelete = async (commentId) => {
      try {
        await deleteDoc(doc(db, "comments", commentId));
        setComments(currentComments => currentComments.filter(comment => comment.id !== commentId)); // Update state
        console.log("Comment deleted successfully");
      } catch (error) {
        console.error("Error deleting comment:", error);
      }

};


  const handleReply = (commentId) => {
    setReplyingComment(commentId);
  };

  const handleReplySubmit = async (e) => {
    e.preventDefault();
    if (!user || replyInput.trim() === "") return;

    const newReply = {
      postId,
      category,
      comment: replyInput,
      username: user.displayName || "Anonymous",
      userId: user.uid,
      createdAt: Timestamp.now(),
      parentId: replyingComment,
    };

    try {
      const docRef = await addDoc(collection(db, "comments"), newReply);
      setComments([...comments, { ...newReply, id: docRef.id }]);
      setReplyInput("");
      setReplyingComment(null);
    } catch (error) {
      console.error("Error submitting reply: ", error);
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div>
      {comments.length > 0 && (
        comments.filter(comment => !comment.parentId).map((comment, index) => (
        <div key={index} className="comments-container">
            <div className="comment-wrapper">    
                <div className="comment-header">
                        <div className="user-date">
                            <span className="comment-user">
                              {comment.username}
                              {comment.userId === postAuthorId && (
                                <strong style={{ color: "purple" }}> Auth</strong>)}

                            </span>
                            <span className="comment-date">
                                {new Date(comment.createdAt.seconds * 1000).toLocaleDateString()}
                            </span>
                        </div>

                        <div className="reply-delete">

                            <span className="reply-name"
                                    onClick={() => handleReply(comment.id)}>
                                Reply
                            </span>

                            {(isAdmin || user?.uid === comment.userId) && (
                                <FaRegTrashAlt className="delete-icon"
                                            onClick={() => handleDelete(comment.id)} />
                            )}
                        </div>                         
                </div>


                <div className="comment-contents">
                    {comment.comment}
                </div>

            </div>    

                

            {replyingComment === comment.id && user && (
              <form onSubmit={handleReplySubmit} className="reply-form"  ref={replyFormRef}>
                <textarea  
                        placeholder="Write a reply..." value={replyInput}
                       onChange={(e) => setReplyInput(e.target.value)} className="reply-input" />
                <button type="submit" className="reply-submit" disabled={isBlocked}>
                    {isBlocked && "你的账号被封，请联系管理员."}
                    提交
                </button>
              </form>
            )}


            <div className="replies-container">
              {comments.filter(reply => reply.parentId === comment.id).map((reply, replyIndex) => (
                <div key={replyIndex} className="reply">
                    <div className="reply-header">

                        <div className="user-date">
                            <div className="reply-user">
                                {reply.username}
                                {reply.userId === postAuthorId && (
                                  <strong style={{ color: "purple" }}> Auth</strong>
                                )}
                                <span className="reply-date">
                                    {new Date(reply.createdAt.seconds * 1000).toLocaleDateString()}
                                </span>
                            </div>
                        </div>

                        <div className="reply-delete">

                            {(isAdmin || user?.uid === reply.userId) && (
                                    <FaRegTrashAlt className="delete-icon"
                                                onClick={() => handleDelete(reply.id)} />
                                )}
                        </div>
                    </div>


                    <div className="reply-contents">{reply.comment}</div>
                </div>
              ))}
            </div>

        </div>
    ))) }
    </div>
  );
};