// blockedUserContext.tsx
import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth } from '../config/firebase.ts';
import { checkIfUserIsBlocked } from './blockuser.tsx';

// Define the type of the context value
interface BlockedUserContextValue {
  isBlocked: boolean;
  checkBlockedStatus: (userId: string) => void; // Define the correct signature with a userId argument
}

// Create the context
const BlockedUserContext = createContext<BlockedUserContextValue>({
  isBlocked: false,
  checkBlockedStatus: () => {} // Default function
});

// Create a provider component
export const BlockedUserProvider = ({ children }) => {
  const [isBlocked, setIsBlocked] = useState(false);

  const checkBlockedStatus = async (userId: string) => {
    const blocked = await checkIfUserIsBlocked(userId);
    setIsBlocked(blocked);
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      checkBlockedStatus(user.uid); // Call it initially to check block status for current user
    }
  }, []);

  return (
    <BlockedUserContext.Provider value={{ isBlocked, checkBlockedStatus }}>
      {children}
    </BlockedUserContext.Provider>
  );
};

// Create a custom hook to use the BlockedUserContext
export const useBlockedUser = () => {
  return useContext(BlockedUserContext);
};
