import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom"; // Use React Router for navigation
import azLifeLogo from '../images/AZ LIFE2.png';
import search_icon from '../images/search.svg';
import notification_icon from '../images/notifications.svg';
import "../components/Navibar.css";
import { signOut, getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { auth, db } from "../config/firebase.ts";
import { useAuthState } from "react-firebase-hooks/auth";
import { collection, getDocs, limit, orderBy, query, updateDoc, where, doc, getDoc, onSnapshot } from "firebase/firestore";
// import { Notification } from '../components/types.ts';
import back_icon from "../images/back-icon.png"

interface Notification {
  id: string;
  userId: string;
  message: string;
  timestamp: {
    seconds: number;
    nanoseconds: number;
  };
  unread: boolean;
  rejected: boolean;
  postId?: string;  
  link?: string;    
}

export const Navibar = () => {
  const [user] = useAuthState(auth);
  const [isAdmin, setIsAdmin] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false); 
  const [hasDropdownBeenOpened, setHasDropdownBeenOpened] = useState(false); // Track if dropdown has been opened
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const notificationRef = useRef<HTMLDivElement>(null); // Ref to track the notification container
  const userMenuRef = useRef<HTMLDivElement>(null); // Ref for the user menu dropdown
  
  const navigate = useNavigate(); // Use React Router for navigation



  const handleSearchClick = () => {
    setIsSearchClicked(true);
    setTimeout(() => {
      setIsSearchClicked(false);  // Revert the color back after 1 second
    }, 150);

    if (searchQuery.trim()) {
      // Perform the search and navigate to search results
      navigate("/search", { state: { searchTerm: searchQuery } });
    }
  };

  // Set Firebase persistence to local
  useEffect(() => {
    const auth = getAuth();
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        console.log("Firebase persistence set to local (remember login)");
      })
      .catch((error) => {
        console.error("Error setting Firebase persistence:", error.message);
      });
  }, []);

  // Check if the user is an admin
  useEffect(() => {
    const checkAdminStatus = async () => {
      if (user) {
        try {
          const idTokenResult = await user.getIdTokenResult();
          setIsAdmin(!!idTokenResult.claims.admin);
        } catch (error) {
          console.error("Error checking admin status:", error);
        }
      }
    };
    checkAdminStatus();
  }, [user]);

  // Fetch notifications from Firestore
  useEffect(() => {
    const fetchNotifications = async () => {
      if (user?.uid) {  
    //     try {
    //       const notificationsRef = collection(db, "notifications");
    //       const q = query(
    //         notificationsRef,
    //         where("userId", "==", user.uid),
    //         orderBy("timestamp", "desc"),
    //         limit(5)
    //       );
    //       const querySnapshot = await getDocs(q);
    //       const userNotifications = querySnapshot.docs.map((doc) => ({
    //         id: doc.id,
    //         ...doc.data(),
    //       })) as Notification[];

    //       setNotifications(userNotifications);
    //       console.log(userNotifications);

    //       // Check if there are unread notifications
    //       const unread = userNotifications.some((notification) => notification.unread);
    //       setHasUnreadNotifications(unread);
    //     } catch (error) {
    //       console.error("Error fetching notifications:", error);
    //     }
    //   }
    // };

    // fetchNotifications();
    const notificationsRef = collection(db, "notifications");
    const q = query(
      notificationsRef,
      where("userId", "==", user.uid),
      orderBy("timestamp", "desc"),
      limit(5)
    );

    // Real-time listener for notifications
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const userNotifications = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Notification[];

      setNotifications(userNotifications);
      console.log('the notifications are: ',userNotifications);

      // Check if there are unread notifications
      const unread = userNotifications.some((notification) => notification.unread);
      setHasUnreadNotifications(unread);
    });

    return unsubscribe;
  }
};
fetchNotifications()

  //   const unsubscribe = fetchNotifications();
  //   return () => {
  //   if (unsubscribe) unsubscribe(); 
  // };
  }, [user]);

  // Mark notifications as read in Firestore and in state
  const markAllNotificationsAsRead = async () => {
    const unreadNotifications = notifications.filter(notification => notification.unread);
    
    if (unreadNotifications.length > 0) {
      try {
        const updatePromises = unreadNotifications.map(notification =>
          updateDoc(doc(db, "notifications", notification.id), { unread: false })
        );
        await Promise.all(updatePromises);

        // Update the state to remove the unread status
        setNotifications(prevNotifications =>
          prevNotifications.map(notification =>
            notification.unread ? { ...notification, unread: false } : notification
          )
        );

        // Remove the red dot after marking as read
        setHasUnreadNotifications(false);
      } catch (error) {
        console.error("Error marking notifications as read:", error);
      }
    }
  };

  // Toggle dropdown visibility for notifications
  const toggleNotifications = () => {
    if (!hasDropdownBeenOpened) {
      // Mark notifications as read when the dropdown is opened for the first time
      markAllNotificationsAsRead();
      setHasDropdownBeenOpened(true); // Track that dropdown has been opened
    }
    setShowNotifications(!showNotifications);
  };

  // Toggle dropdown visibility for user menu
  const toggleUserMenu = () => {
    setShowUserMenu(!showUserMenu);
  };

  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target as Node)) {
        setShowNotifications(false);
      }
      if (userMenuRef.current && !userMenuRef.current.contains(event.target as Node)) {
        setShowUserMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Sign the user out and redirect to the sign-in page
  const signUserOut = async () => {
    await signOut(auth);
    setIsAdmin(false); 
    // navigate("/signinwithemail");
    navigate("/");
  };

  const [searchQuery, setSearchQuery] = useState(""); 
  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    // if (searchQuery.trim()) {
    // //   navigate(`/search?q=${encodeURIComponent(searchQuery)}`); // Redirect to search page with query
    // // }
    // console.log("Search Query:", searchQuery); 
    // navigate("/search", { state: { searchTerm: searchQuery } });
    // };
    handleSearchClick();
  }

  const [maintenanceMessage, setMaintenanceMessage] = useState("");

  useEffect(() => {
    // Fetch maintenance message from Firestore
    const fetchMessage = async () => {
      const docRef = doc(db, 'maintenance', '2Iv3f1ULYD96Apdkzh3');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data()); 
        setMaintenanceMessage(docSnap.data().currentMessage);
        console.log('messg', maintenanceMessage)
      } else {
        // console.log("No maintenance message found.");
      }
    };

    fetchMessage();
  }, []);

    // Event handler for key down event
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSearchClick(); // Trigger the search when Enter is pressed
      }
    }

    const SearchInput = () => {
      const searchInputRef = useRef<HTMLInputElement>(null);
      const [searchQuery, setSearchQuery] = useState('');  // Store the input value
      
      useEffect(() => {
        const input = searchInputRef.current;
    
        const handleFocus = () => {
          const viewport = document.querySelector('meta[name="viewport"]');
          if (viewport) {
            viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');
          }
        };
    
        const handleBlur = () => {
          const viewport = document.querySelector('meta[name="viewport"]');
          if (viewport) {
            viewport.setAttribute('content', 'width=device-width, initial-scale=1.0');
          }
        };
    
        if (input) {
          input.addEventListener('focus', handleFocus);
          input.addEventListener('blur', handleBlur);
        }
    
        // Cleanup event listeners on component unmount
        return () => {
          if (input) {
            input.removeEventListener('focus', handleFocus);
            input.removeEventListener('blur', handleBlur);
          }
        };
      }, []);
    
      const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);  // Update the search query state
      };
    
      const handleSearchClick = () => {
        // Logic to perform search or other action when clicking the search button
        console.log("Search Query:", searchQuery);
      };
    }    

  return (

    <div className="header-container">

      <div>
          {maintenanceMessage && (
            <div style={{ backgroundColor: "red", color: "white", textAlign: "center", padding: "10px" }}>
              ⚠️ {maintenanceMessage} ⚠️
            </div>
          )}
        </div>




      <header className="navbar">
        <div className="left-section">
              <div className="logo">
                  <Link to="/" className="link">
                    <img className="azlife-logo" src={azLifeLogo} alt="AZ LIFE Logo" width="60px" />
                    </Link>
              </div>

              {/* <div className="links">
                    <Link to="/" className="link">主页</Link>
              </div> */}
        </div>

        <div className="middle-section" onSubmit={handleSearch}>
             
                
                  <input 
                  type="text"
                  placeholder="搜索" 
                  className="search-input"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyDown={handleKeyDown}   />
            

              {/* <Link to='/search'> */}

              
              
                  <button 
                        onClick={handleSearchClick}
                        className={`search_button ${isSearchClicked ? 'search-active' : ''}`}>
                              <img src={search_icon} 
                                alt="search_icon" 
                                className="search_icon"
                              />
                    </button>
                  {/* </Link> */}
                

        </div>

        <div className="user-container">
        
            {!user ? (
              <Link to="/signinwithemail">
                <button className="login">登录</button>
              </Link>
            ) : (
                        <div className="post-notification" ref={notificationRef}>
                                         
                                                    
                                         <button className="createpost">
                                                          <Link to="/createpost">发帖</Link>
                                                        </button>
                                                  

                                            <div className={`notification-container ${showNotifications ? 'show' : ''}`}>
                                                 
                                                     
                                                  <div>
                                                 
                                                      <img
                                                        src={notification_icon}
                                                        alt="notification_icon"
                                                        className="notification_icon"
                                                        onClick={toggleNotifications}
                                                      />
                                                  
                                                 
                                                  {hasUnreadNotifications && <span className="red-dot"></span>}

                                                  <div className="tooltip">
                                                            通知
                                                  </div>
                                                  </div>

                                                                  {showNotifications && (
                                                                            <div className="notification-list">
                                                                                                  {notifications.length > 0 ? (
                                                                                                    notifications.map((notification) => (
                                                                                                      <div key={notification.id} className="notification-item">
                                                                                                        <div>
                                                                                                          {notification.unread && <span className="unread-spot"></span>}
                                                                                                        </div>
                                                                                                        <div className={`notification-message ${notification.rejected ? "notification-rejected" : "notification-approved"}`}>
                                                                                                        {notification.link ? (
                                                                                                          <Link to={notification.link}>
                                                                                                            <p>{notification.message}</p>
                                                                                                          </Link>
                                                                                                        ) : (
                                                                                                          <p>{notification.message}</p>
                                                                                                        )}
                                                                                                        </div>
                                                                                                        <div className="notification-timestamp">
                                                                                                          <small>{new Date(notification.timestamp.seconds * 1000).toLocaleString()}</small>
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    ))
                                                                                                  ) : (
                                                                                                    <div className="notification-item">No notifications</div>
                                                                                                  )}
                                                                            </div>
                                                                  )}
                                                      </div>
                                              </div>
                                )}

                            
                                           {user && (<div className="user-info" ref={userMenuRef}>
                                                    <div className="username-container" onClick={toggleUserMenu}>
                                                      <p className="username">{user?.displayName}</p>
                                                      <span className="dropdown-icon">&#9660;</span>
                                                    </div>

                                                    {showUserMenu && (
                                                      <div className="user-menu">
                                                        
                                                        <Link to="/myposts" className="myposts">我的帖子</Link>
                                                        <Link to="/mycollections" className="mycollections">我的收藏</Link>
                                                        <Link to="/readme" className="mycollections">用户须知</Link>
                                                        <button onClick={signUserOut} className="exit-btn">退出</button>
                                                      </div>
                                                    )}
                                            </div>) 
                                            }  
                                            
                                            
                </div>   
                {isAdmin && (
                              <Link to="/admin_post">
                                <button className="posts-btn">Posts</button>
                              </Link>
                            )}       

                
                

                
      </header>
    </div>
  );
  
};
