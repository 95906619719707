import React, { useState, useRef, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { auth, db, storage } from "../config/firebase.ts";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import logo from '../images/AZ LIFE2.png';
import "./create_post.css";
import {generateCategoryOptions} from "./categorymap.tsx"
import { useBlockedUser } from "../admin/blockedusercontext.tsx";
import Quill from "quill"; // Import Quill
import "quill/dist/quill.snow.css"; // Import Quill styling
import DOMPurify from "dompurify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Table from "quill-table";  // Import the table module
import  SlateEditor from "./slate_table.tsx"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


// Use Parchment's BlockEmbed
const BlockEmbed:any = Quill.import('blots/block/embed'); 

interface CreateFormData {
  title: string;
  // description: string;
  category: string;
}



class ImageBlot extends BlockEmbed {
  static create(value) {
    const node = super.create();
    node.setAttribute('src', value.src);
    node.setAttribute('alt', value.alt || 'Image');
    node.style.width = value.width || '150px';  // Set width here
    return node;
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.style.width,
    };
  }
}

ImageBlot.blotName = 'imageBlot';
ImageBlot.tagName = 'img';
Quill.register(ImageBlot);
// Ensure Quill registers the Table module globally
Quill.register("modules/table", Table, true);

export const CreatePost = () => {

  const [user] = useAuthState(auth);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [imageError, setImageError] = useState<string | null>(null);
  const [submissionMessage, setSubmissionMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false); // Admin status
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [captions, setCaptions] = useState<string[]>([])
  const { isBlocked } = useBlockedUser();
  const quillRef = useRef<HTMLDivElement>(null); // Ref for Quill editor
  const [description, setDescription] = useState<string>("");
  const [quillInstance, setQuillInstance] = useState<any>(null); // Store Quill instance

  const removeExtraBreaks = (html) => {
    return html.replace(/<p>(\s|<br>|&nbsp;)*<\/p>/g, ""); // Removes <p> tags containing only <br> or whitespace
  };


  
  const handleDeleteImage = async (index: number) => {
    // Get the URL of the image to be deleted from Firebase Storage
    const imageUrl = imagePreviews[index];
    
    // Remove image preview, selected file, and caption from state
    setImagePreviews((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setCaptions((prevCaptions) => prevCaptions.filter((_, i) => i !== index));
  
    // Check if the image URL points to a Firebase Storage location and delete it if it exists
    if (imageUrl.startsWith("https://firebasestorage.googleapis.com")) {
      try {
        const storageRef = ref(storage, imageUrl); // Create a reference to the storage location
        await deleteObject(storageRef); // Delete the file from Firebase Storage
        console.log(`Deleted image: ${imageUrl}`);
      } catch (error) {
        console.error("Error deleting image from storage:", error);
      }
    }
  };
  
   const sanitizedDescription = useMemo(() => {
    // First, sanitize the HTML
    let sanitizedHtml = DOMPurify.sanitize(description || "", {
      ALLOWED_TAGS: ["h1", "h2", "b", "i", "em", "strong", "p", "ul", "ol", "li", "a", "img", "br", "span"],
      ALLOWED_ATTR: ["href", "src", "alt", "style", "data-list", "target", "class"],
      ALLOWED_CLASSES: {
        "*": ["ql-size-large", "ql-size-small"] // Allow ql-size-large and ql-size-small on any tag
      }
    });
  
    // Remove empty <p> tags
    sanitizedHtml = removeExtraBreaks(sanitizedHtml);
  
    return sanitizedHtml;
  }, [description]);


  const schema = yup.object().shape({
    title: yup.string().required("You must add a title"),
    // description: yup.string().required("Add description"),
    category: yup.string().required("Select a topic"),
  });


  // Handle caption input changes
  const handleCaptionChange = (index: number, caption: string) => {
    const newCaptions = [...captions];
    newCaptions[index] = caption;
    setCaptions(newCaptions);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateFormData>({
    resolver: yupResolver(schema),
  });

  const postsRef = collection(db, "posts");



// Resize the iamge
  // Resize and compress image to stay under 1MB with 300px width
const resizeImage = (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target?.result as string;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const TARGET_WIDTH = 300;
        let width = img.width;
        let height = img.height;

        // Scale down width to 300px while maintaining aspect ratio
        if (width > TARGET_WIDTH) {
          height = Math.floor((height * TARGET_WIDTH) / width);
          width = TARGET_WIDTH;
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0, width, height);

        // First attempt: Resize and set initial quality
        canvas.toBlob(
          (blob) => {
            if (blob && blob.size > 1024 * 1024) {
              // Compress further if still over 1 MB
              canvas.toBlob(
                (compressedBlob) => {
                  if (compressedBlob) {
                    const resizedFile = new File([compressedBlob], file.name, {
                      type: file.type,
                      lastModified: Date.now(),
                    });
                    resolve(resizedFile);
                  } else {
                    reject(new Error("Failed to compress image to under 1MB"));
                  }
                },
                file.type,
                0.6 // Reduce quality further to keep under 1 MB
              );
            } else if (blob) {
              const resizedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              resolve(resizedFile);
            } else {
              reject(new Error("Failed to process image"));
            }
          },
          file.type,
          1.0 // Start with full quality for resizing
        );
      };
    };
    reader.readAsDataURL(file);
  });
};



  
  // Handle file selection and preview
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const processFile = async(file: File): Promise<File | null> => {
      // const isValidSize = file.size <= 1024 * 1024; 
      const isValidType = [
        "image/jpeg", 
        "image/png", 
        "image/gif", 
        "image/heic", 
        "image/heif", 
        "image/bmp", 
        "image/tiff", 
        "image/x-tiff"
      ].includes(file.type);
      // return isValidSize && isValidType;
    
        if (!isValidType) {
          setImageError("Unsupported file type.");
          return null;
        }
    
    
    if (!isValidType) {
      setImageError("Unsupported file type");
      return null;
    }
    
        // Resize if larger than 1MB
        if (file.size > 1024 * 1024) {
          return resizeImage(file); 
        } else {
          return Promise.resolve(file); // No resizing needed
        }
      };

    const files = e.target.files ? Array.from(e.target.files) : [];
    Promise.all(files.map(processFile))
    .then((validFiles) => {
      // const filteredFiles = validFiles.filter(Boolean); 
      const filteredFiles = validFiles.filter((file): file is File => file !== null);
      // setSelectedFiles(filteredFiles as File[]); // Update state with valid files
      setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...filteredFiles]);

      console.log('selected files: ', selectedFiles);


      // const previews = filteredFiles.map((file) => URL.createObjectURL(file));
      // setImagePreviews(previews);

      const newPreviews = filteredFiles.map((file) => URL.createObjectURL(file));
      setImagePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
       // Add empty captions for each new image
       setCaptions((prevCaptions) => [...prevCaptions, ...new Array(filteredFiles.length).fill("")]);
         // Clear the file input field after handling the selection
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    })
    .catch((error) => {
      setImageError(`Error processing files: ${error.message}`);
    });
  };
    

  // Check if the user is admin based on custom claims
  useEffect(() => {
    const checkAdmin = async () => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        if (idTokenResult.claims.admin) {
          setIsAdmin(true);
        }
      }
    };
    checkAdmin();
  }, [user]);

  // Define a type that extends HTMLDivElement to include __quill
interface QuillDivElement extends HTMLDivElement {
  __quill?: any; // Add __quill as an optional property
}


 // Quill toolbar modules with table options
 const modules = {
  toolbar: [
    // [{ header: [1, 2, false] }],
    [{ size: ["small", false, "large"] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    // [{ table: "insert-table" }],
    // [
    //   { table: "insert-row-above" },
    //   { table: "insert-row-below" },
    //   { table: "delete-row" },
    //   { table: "insert-column-right" },
    //   { table: "insert-column-left" },
    //   { table: "delete-column" },
    // ],
    // [{ table: "delete-table" }],
  ],

};



// Good codes for the input
// useEffect(() => {
//   const currentRef = quillRef.current as QuillDivElement;


//   if (!currentRef) {
//     console.error("Quill ref is not initialized");
//     return;
//   }

//   if (!currentRef.__quill) {
//     // Initialize Quill editor only if it hasn't been initialized yet
//     const quillInstance = new Quill(currentRef, {
//       theme: "snow",
//       // placeholder: "Write the description here...",
//       // modules,
//       modules: {
//         toolbar: [
//           [{ header: [1, 2, false] }],
//           ["bold", "italic", "underline"],
//           [{ list: "ordered" }, { list: "bullet" }],
//           ["link"],
//           [{ table: "insert-table" }], // Table insertion button
//           [{ table: "insert-row-above" }, { table: "insert-row-below" }],
//           [{ table: "insert-column-right" }, { table: "insert-column-left" }],
//           [{ table: "delete-row" }, { table: "delete-column" }, { table: "delete-table" }]
//           // ["link", "image"],
//         ],
      

//         // handlers: {
//         //   image: handleImageUpload, 
//         // },
        
//       },

//       // modules: {
//       //   toolbar: {
//       //     container: [
//       //       [{ header: [1, 2, false] }],
//       //       ["bold", "italic", "underline", "strike"],
//       //       [{ list: "ordered" }, { list: "bullet" }],
//       //       ["link", "image"],
//       //       [{ table: "insert-table" }], // Button to create a table
//       //       [
//       //         { table: "insert-row-above" },
//       //         { table: "insert-row-below" },
//       //         { table: "delete-row" },
//       //         { table: "insert-column-right" },
//       //         { table: "insert-column-left" },
//       //         { table: "delete-column" }
//       //       ],
//       //       [{ table: "delete-table" }] // Button to delete the entire table
//       //     ],
//       //   },
//       //   table: true, // Enable the table module in Quill
//       // }
//     });

//     currentRef.__quill = quillInstance;

//     // Add text-change event listener
//     quillInstance.on("text-change", () => {
//       const content = quillInstance.root.innerHTML;
//       setDescription(content);
//     });
//   }
// }, [])

  

  // Custom function to handle image upload to Firebase Storage
  const handleImageUpload = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
  
    input.onchange = async () => {
      const file = input.files ? input.files[0] : null;
      if (file) {
        const quill = quillRef.current ? (Quill.find(quillRef.current) as Quill) : null;
        const range = quill?.getSelection();
  
        if (range) {
          // Insert "Image loading..." placeholder at the current cursor position
          quill?.clipboard.dangerouslyPasteHTML(range.index, `<p>Image loading...</p>`);
          
          // Store the index of where the "Image loading..." placeholder was inserted
          const loadingIndex = range.index;
  
          try {
            // Resize the image if it's larger than 1MB
            const resizedFile = file.size > 1024 * 1024 ? await resizeImage(file) : file;
            const storageRef = ref(storage, `images/${Date.now()}_${file.name}`);
            
            // Upload the image to Firebase Storage
            await uploadBytes(storageRef, resizedFile);
            const imageUrl = await getDownloadURL(storageRef);
            
            // Once uploaded, remove the "Image loading..." placeholder and insert the actual image
            quill?.deleteText(loadingIndex, 'Image loading...'.length); // Remove placeholder
            quill?.clipboard.dangerouslyPasteHTML(loadingIndex, `<img src="${imageUrl}" alt="Uploaded Image" style="width: 150px"/>`);
          } catch (error) {
            console.error("Error uploading image: ", error);
            quill?.deleteText(loadingIndex, 'Image loading...'.length); // Remove placeholder if there's an error
            quill?.clipboard.dangerouslyPasteHTML(loadingIndex, `<p>Failed to upload image</p>`); // Replace with error message
          }
        }
      }
    };
  };
  
  const stripHtmlTags = (html: string) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  // const sanitizeHtml = (html: string) => {
  //   return DOMPurify.sanitize(html, {
  //     ALLOWED_TAGS: ["b", "i", "em", "strong", "p", "ul", "ol", "li", "a", "img"],
  //     ALLOWED_ATTR: ["href", "src", "alt", "style", 'class'],
  //   });
  // };
  



const cleanDescription = (descriptionHTML: string) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = descriptionHTML;

  // Remove base64 images
  tempDiv.querySelectorAll("img").forEach((img) => {
    const src = img.getAttribute("src") || "";
    if (src.startsWith("data:image")) {
      img.remove(); // Remove any base64 images
    }
  });

  // Remove empty <p> tags
  tempDiv.querySelectorAll("p").forEach((p) => {
    if (p.innerHTML.trim() === "") {
      p.remove();
    }
  });

  return tempDiv.innerHTML;
};




  const onCreatePost = async (data: CreateFormData) => {
    console.log("Submitting post with data:", data);
   
    // alert("Submit function triggered");  
    setIsSubmitting(true);

    const imageUrls: string[] = [];

    // Block non-admin users from submitting "精华帖" or "市场推广" categories
    if (!isAdmin && (data.category === "hotposts" || data.category === "marketing")) {
      setSubmissionMessage("Only admins can assign 精华帖 or 市场推广 categories.");
      setIsSubmitting(false);
      return;
    }

    // const cleanedDescription = stripHtmlTags(cleanDescription(description));
    // const sanitizedDescription = sanitizeHtml(description);



    try {
      // Upload selected files to Firebase Storage
      if (selectedFiles.length > 0) {
        // for (const file of selectedFiles) {
        //   const storageRef = ref(storage, `posts/${Date.now()}_${file.name}`);
        //   await uploadBytes(storageRef, file);
        //   const imageUrl = await getDownloadURL(storageRef);
        //   imageUrls.push(imageUrl);
        // }
        const uploadPromises = selectedFiles.map(async (file) => {
          const storageRef = ref(storage, `posts/${Date.now()}_${file.name}`);
          await uploadBytes(storageRef, file);
          const imageUrl = await getDownloadURL(storageRef);
          imageUrls.push(imageUrl); // Append URL to the imageUrls array
        });
  
        // Wait for all images to be uploaded
        await Promise.all(uploadPromises);
      }

      console.log('image urls: ', imageUrls)

      const keywords = data.title.split("").map(char => char.trim()).filter(char => char !== "");

      // Add post data to Firestore
      await addDoc(postsRef, {
        title: data.title,
        // description: data.description,
        description:  sanitizedDescription, // Use cleaned description here
        category: data.category,
        username: user?.displayName,
        userId: user?.uid,
        createdAt: Timestamp.now(),
        imageUrls,
        captions, // Save captions
        approved: isAdmin ? true : false, 
        rejected: false,
        rank: 0,
        keywords: keywords || [],
      });
      console.log("Post submitted successfully!"); 
     
      setSubmissionMessage(`帖子【${data.title}】已经提交，等待审核.`);

      reset();
      setSelectedFiles([]);
      setImagePreviews([]);
      setCaptions([]); // Clear captions after submission
      setDescription(""); // Clear description after submission


      // Clear the Quill editor content
      const currentRef = quillRef.current as QuillDivElement;
      if (currentRef && currentRef.__quill) {
        currentRef.__quill.setContents([]); // Clear the editor content
      }
   
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Reset file input field
      }

    } catch (error) {
      console.error("Error submitting post:", error);
      setSubmissionMessage("There was an error submitting your post.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="form-container">
      <div className="signup-logo">
        <Link to="/">
          <img src={logo} className='logo-img' alt="Logo" />
        </Link>
      </div>

      <form onSubmit={handleSubmit(onCreatePost)} className="form">
        <h1>发表主题</h1>

        <input placeholder="Title .." {...register("title")} />
        <p style={{ color: "red" }}>{errors.title?.message}</p>

        {/* <textarea placeholder="Description .." {...register("description")} />
        <p style={{ color: "red" }}>{errors.description?.message}</p> */}

          {/* Replace the textarea with a rich-text editor */}
          {/* <div ref={quillRef} style={{ height: "200px" }}></div> */}
          <div className="ql-wrapper">
            <ReactQuill
                value={description}
                onChange={setDescription}
                modules={modules}
                theme="snow"
                placeholder="Write the description here..."
                className="create-post-edit"
            />
        </div>



        <select {...register("category")} className="custom-select">
          <option value="">选择主题</option>
          {/* {isAdmin && <option value="hotposts">精华帖</option>}
          {isAdmin && <option value="marketing">商业推广</option>}
          <option value="sell">出售</option>
          <option value="buy">求购</option>
          <option value="for-rent">出租/招租</option>
          <option value="to-rent">求租</option>
          <option value="complain">我要吐槽</option>
          <option value="others">其他</option> */}
          {generateCategoryOptions(isAdmin)};
        </select>
        <p style={{ color: "red" }}>{errors.category?.message}</p>

        {/* Handle multiple image file input */}
        <input 
              type="file" 
              onChange={handleFileChange} 
              multiple className="submit-file" 
              ref={fileInputRef} 
              accept="image/*" 
        />


              
        {imageError && <p style={{ color: "red" }}>{imageError}</p>}

        {/* Preview multiple images with captions */}
        {imagePreviews.length > 0 && (
          <div className='image-preview-container'>
            <div className="image-previews">
              {imagePreviews.map((preview, index) => (
                <div key={index} className="image-with-caption">
                  <img src={preview} alt={`Preview ${index}`} style={{ width: "150px" }} />
                    <button
                        onClick={() => handleDeleteImage(index)}
                        className="delete-image-btn"
                      >
                        Delete
                  </button>
                  <div>
                      <input
                        type="text"
                        placeholder="Add caption"
                        value={captions[index] || ""}
                        onChange={(e) => handleCaptionChange(index, e.target.value)}
                        className="caption-input"
                      />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}


        <button type="submit" className="submitform" disabled={isSubmitting || isBlocked} >
          {isBlocked && "你的账号已经被封，请联系管理员"}
          {isSubmitting ? "提交中..." : "提交"}
        </button>

        {submissionMessage && <p className='submit-status'>{submissionMessage}</p>}
      </form>
    </div>
  );
};
