import React, { useEffect, useState } from "react";
import { collection, query, where, onSnapshot, getDoc, doc } from "firebase/firestore";
import { db, auth } from "../config/firebase.ts"; // Import your Firebase setup
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navibar } from "../components/Navibar.tsx";
import "./mycollections.css"; 

interface Post {
  id: string;
  title: string;
  category: string;
  createdAt: { seconds: number; nanoseconds: number };
  views: number;
  collections: number;
}

export const MyCollections = () => {
  const [collections, setCollections] = useState<Post[]>([]);
  const [user] = useAuthState(auth);


  useEffect(() => {
    if (user) {
      const collectionsRef = collection(db, "collections");
      const myCollectionsQuery = query(collectionsRef, where("userId", "==", user.uid));

      const unsubscribe = onSnapshot(myCollectionsQuery, async (snapshot) => {
        const fetchedPosts: Post[] = [];

        for (const docSnap of snapshot.docs) {
          const postRef = doc(db, "posts", docSnap.data().postId);
          const postDoc = await getDoc(postRef);
          if (postDoc.exists()) {
            fetchedPosts.push({ id: postDoc.id, ...postDoc.data() } as Post);
          }
        }
        setCollections(fetchedPosts);
      });

      return () => unsubscribe();
    }
  }, [user]);

  if (!user) return <p>Please log in to view your collections.</p>;


  return (
    <div className="my-collections">
         <div className="collection-header">
            <Navibar />
         </div>
        
        <div className="collection-container">
            <h3>我的收藏</h3>
        {collections.length > 0 ? (
          <table className="posts-table">
                <thead>
                <tr>
                <th className="title-column">标题</th>
                <th className="views-column">浏览量</th>
                <th className="collections-column">收藏数</th>
                <th className="created-column">发帖时间</th>
                </tr>
            </thead>
            <tbody>
                {collections.map(post => (
                <tr key={post.id}>
                    <td className="title-column">
                        <Link to={`/category/${post.category}/${post.id}`} className="post-link">
                            {post.title}
                        </Link>
                    </td>
                    <td className="views-column">{post.views || 0}</td>
                    <td className="collections-column">{post.collections || 0}</td>
                    <td className="created-column">{new Date(post.createdAt.seconds * 1000).toLocaleDateString()}</td>
                </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <p className="no-posts">没有帖子</p>
        )}
        
        </div>
    </div>
  );
};
