import { collection, doc, getDocs, onSnapshot, orderBy, query, Timestamp, updateDoc, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../config/firebase.ts";
import { Link, useNavigate } from "react-router-dom";
import { Navibar } from "../components/Navibar.tsx";
import "./category_posts.css";
import { CategoryMap } from './categorymap.tsx';

import back_icon from "../images/back-icon.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faHeart, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';


// Define the Post interface
interface Post {
  id: string;
  title: string;
  description: string;
  category: string;
  username?: string;
  imageUrls: string[];
  createdAt: Timestamp;
  views: number;  // Add views to the post structure
  collections: number; // Add collections count
  rank: number;
  deleted?: boolean;
}

interface CategoryPostsProps {
  category: string; // The category that will be passed as a prop
  isAdmin: boolean; // Check if the current user is an admin
}

export const CategoryPosts: React.FC<CategoryPostsProps> = ({ category, isAdmin }) => {
  const [posts, setPosts] = useState<Post[]>([]); // Store posts for the selected category
  // Helper function to fetch and store posts by category
  const fetchCategoryPosts = (category: string) => {
    const postsRef = collection(db, "posts");
    const postsQuery = query(
      postsRef,
      where("category", "==", category),  // Query posts by category
      where("approved", "==", true),      // Only get approved posts
    //   orderBy("rank", "desc"),         // Order by createdAt in descending order
    //   orderBy("createdAt", "desc"),
   
    );

  // Use onSnapshot to listen for real-time updates
    onSnapshot(postsQuery, (snapshot) => {
      const postsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Post));
              // Client-side sorting: First by rank, then by createdAt
      const sortedPosts = postsData
      .sort((a, b) => {
        if ((b.rank || 0) !== (a.rank || 0)) {
          return (b.rank || 0) - (a.rank || 0);  // Sort by rank first (higher rank first)
        }
        return b.createdAt.seconds - a.createdAt.seconds;  // Sort by createdAt if ranks are the same
      });
      setPosts(sortedPosts);  // Update the state with the sorted posts

    //   setPosts(postsData);  // Update the state with the fetched posts
    }, (error) => {
      console.error(`Error fetching posts for ${category}: `, error);
    });
  };

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    fetchCategoryPosts(category); // Fetch posts when the component mounts or category changes
  }, [category]);

  // Function to rank a post to the first position (highest rank)
  const rankPostToFirst = async (postId: string) => {
    try {
      // Fetch all posts in the current category and find the highest rank
      const postsRef = collection(db, "posts");
      const postsQuery = query(postsRef, where("category", "==", category), orderBy('createdAt', 'desc'));
      const snapshot = await getDocs(postsQuery);
      
      const highestRank = Math.max(...snapshot.docs.map(doc => doc.data().rank || 0));

      // Set this post's rank to be one higher than the current highest rank
      const newRank = highestRank + 1;

      // Update the post with the new rank in Firestore
      const postDoc = doc(db, "posts", postId);
      await updateDoc(postDoc, { rank: newRank });

      alert("Post has been ranked to first!");
    } catch (error) {
      console.error("Error ranking post to first:", error);
    }
  };


  

  return (
    <div className="category-posts">
      <div className="header-posts">
        <Navibar />
      </div>
      
      <div className="container-posts">

        <div className="header-line">
           <img src={back_icon} onClick={handleBack} width="35px" className="back-btn"/>
            <h3 className="header-category">
                {/* {category.charAt(0).toUpperCase() + category.slice(1)} */}
              
            
                {CategoryMap[category]}
                {/* <Link to={`/category/${category}`}>
                  
                </Link> */}
            </h3>
        </div>

        {posts.length > 0 ? (
          <table className="posts-table">
                <thead>
                <tr>
                <th className="title-column">标题</th>
                {/* <th className="views-column">浏览量</th>
                <th className="collections-column">收藏数</th>
                <th className="created-column">发帖时间</th> */}
                  <th className="views-column"><FontAwesomeIcon icon={faEye} size="sm"/></th>
                <th className="collections-column"><FontAwesomeIcon icon={faHeart} style={{ color: 'red' }} size="sm"/> </th>
                <th className="created-column"><FontAwesomeIcon icon={faCalendarAlt} size="sm"/></th>

                </tr>

            </thead>
            <tbody>
                {posts.filter(post => post.deleted !== true) 
                .map(post => (
                <tr key={post.id}>
                    <td className="title-column">
                        <Link to={`/category/${post.category}/${post.id}`} className="post-link">
                            {post.title}
                        </Link>
                    </td>
                    <td className="views-column">{post.views || 0}</td>
                    <td className="collections-column">{post.collections || 0}</td>
                    <td className="created-column">{new Date(post.createdAt.seconds * 1000).toLocaleDateString()}</td>
            
                    {isAdmin && (
                      <td>
                        <button onClick={() => rankPostToFirst(post.id)}>
                          Rank First
                        </button>
                      </td>

                    )}
                </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <p className="no-posts">没有贴子</p>
        )}
      </div>
    </div>
  );
};
