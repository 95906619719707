import React, { useEffect, useState } from "react";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db, auth } from "../config/firebase.ts"; // Import your Firebase setup
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navibar } from "../components/Navibar.tsx";
// import "./mycollections.css"; 
import "./myposts.css"
  
import back_icon from "../images/back-icon.png"
  


interface Post {
  id: string;
  title: string;
  category: string;
  createdAt: { seconds: number; nanoseconds: number };
  views: number;
  collections: number;
  userId: string; // Add this to match your query
}

export const MyPosts = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [user] = useAuthState(auth);

    
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const postsRef = collection(db, "posts");
      const myPostsQuery = query(postsRef, where("userId", "==", user.uid));

      const unsubscribe = onSnapshot(myPostsQuery, (snapshot) => {
        const fetchedPosts: Post[] = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Post[];
        setPosts(fetchedPosts);
      });

      return () => unsubscribe();
    }
  }, [user]);

  if (!user) return <p>Please log in to view your posts.</p>;

  return (
    <div className="my-posts">
        <div className="collection-header">
            <Navibar />
         </div>
        
    <div className="collection-container">

        <div className="header-line-post">

          {/* <img src={back_icon} onClick={() => navigate(-1)} width="35px" className="back-btn"/> */}
          <h3>我的帖子</h3>

        </div>

        {posts.length > 0 ? (
          <table className="posts-table">
                <thead>
                <tr>
                <th className="title-column">标题</th>
                <th className="views-column">浏览量</th>
                <th className="collections-column">收藏数</th>
                <th className="created-column">发帖时间</th>
                </tr>
            </thead>
            <tbody>
                {posts.map(post => (
                <tr key={post.id}>
                    <td className="title-column">
                        <Link to={`/category/${post.category}/${post.id}`} className="post-link">
                            {post.title}
                        </Link>
                    </td>
                    <td className="views-column">{post.views || 0}</td>
                    <td className="collections-column">{post.collections || 0}</td>
                    <td className="created-column">{new Date(post.createdAt.seconds * 1000).toLocaleDateString()}</td>
                </tr>
                ))}
            </tbody>
          </table>
        ) : (
            <p className="no-posts">没有帖子</p>
        )}
        
    </div>
    </div>
  );
};
