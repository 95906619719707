import React, { useEffect, useMemo, useRef, useState } from "react";
import { doc, getDoc, updateDoc, setDoc, deleteDoc, clearIndexedDbPersistence, collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { db, auth } from "../config/firebase.ts";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Navibar } from "../components/Navibar.tsx";
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as filledHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as outlinedHeart } from "@fortawesome/free-regular-svg-icons";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"; // Import specific icon
import "./post.css";
// import html2canvas from "html2canvas";
import {AddComment} from "../pages/comments_form.tsx"
import {CommentsDisplay} from "../pages/comments_display.tsx"
// import { Notification } from '../components/types.ts';
import domtoimage from "dom-to-image";
import {CategoryMap, generateCategoryOptions} from "../pages/categorymap.tsx"
import { Helmet } from 'react-helmet';
import DOMPurify from "dompurify";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';





interface Post {
  id: string;
  title: string;
  description: string;
  category: string;
  username: string;
  imageUrls: string[];
  captions: string[];  // <-- Add this line to include captions
  collections: number;
  views: number;
  userId: string;
  createdAt: { seconds: number; nanoseconds: number }; // Timestamp for created time
  keywords?: string[];
}


export const PostDetail = () => {
  const { postId } = useParams<{ postId: string }>();
  const [post, setPost] = useState<Post | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState<Post | null>(null);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [newImages, setNewImages] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [category, setCategory] = useState<string>("sell");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [user] = useAuthState(auth);
  const [isCollected, setIsCollected] = useState<boolean>(false); // State for collection status
  const [isAdmin, setIsAdmin] = useState<boolean>(false); // State for admin check
  const storage = getStorage();
  const [captions, setCaptions] = useState<string[]>([]);  
  const [newCaptions, setNewCaptions] = useState<string[]>([]); // State to hold captions for new images
  const descriptionRef = useRef<HTMLDivElement | null>(null);
  const [renderTrigger, setRenderTrigger] = useState(false); 
  const [isDeleted, setIsDeleted] = useState(false);
  const [posts, setPosts] = useState<Post[]>([]);
  const [deleteAllImages, setDeleteAllImages] = useState(false);
  

  useEffect(() => {
    console.log('Updated captions:', captions);
}, [captions]); // This logs only after captions have actually updated

useEffect(() => {
  if (post && post.captions) {
    setCaptions([...post.captions]); // Initialize captions to existing values, empty or not
  }
}, [post]);
  const twoWeeksInMilliseconds = 14 * 24 * 60 * 60 * 1000; // 14 days
  const navigate = useNavigate(); // Use navigate to go back to the category page

  // useEffect(() => {
  //   // Check if post and post.imageUrls are defined
  //   if (post && post.imageUrls) {
  //     // Adjust captions to match the number of imageUrls
  //     const adjustedCaptions = post.imageUrls.map((url, index) => captions[index] || "");
  //     setCaptions(adjustedCaptions);
  //   }

  //   console.log("hooked captions: ", captions)
  // }, []); // Depend on post.imageUrls to update when images change
  

  // **Detect if the user is on a mobile device**
  const isMobileDevice = /Mobi|Android|iPad|iPhone|iPod/.test(navigator.userAgent);
    // Track description changes in editData to ensure post syncs correctly
    useEffect(() => {
      if (editData?.description) {
        setPost((prev) => prev ? { ...prev, description: editData.description } : prev);
      }
    }, [editData?.description]);
  

  // // Function to sanitize the HTML content
  // const sanitizeHtml = (html: string) => {
  //   return DOMPurify.sanitize(html, {
  //     ALLOWED_TAGS: ["h1", "h2", "b", "i", "em", "strong", "p", "ul", "ol", "li", "a", "img", "br", "span"],
  //     ALLOWED_ATTR: ["href", "src", "alt", "style", "data-list", "target"], // Include data-list for Quill lists
  //   });
  // };
        // Memoize sanitized description for performance
  // const sanitizedDescription = useMemo(() => sanitizeHtml(post?.description || ""), [post?.description]);
 // Memoize the sanitized description to avoid recalculating unnecessarily

 const removeExtraBreaks = (html) => {
  return html.replace(/<p>(\s|<br>|&nbsp;)*<\/p>/g, ""); // Removes <p> tags containing only <br> or whitespace
};

 const sanitizedDescription = useMemo(() => {
  // First, sanitize the HTML
  let sanitizedHtml = DOMPurify.sanitize(post?.description || "", {
    ALLOWED_TAGS: ["h1", "h2", "b", "i", "em", "strong", "p", "ul", "ol", "li", "a", "img", "br", "span"],
    ALLOWED_ATTR: ["href", "src", "alt", "style", "data-list", "target", "class"],
    ALLOWED_CLASSES: {
      "*": ["ql-size-large", "ql-size-small"] // Allow ql-size-large and ql-size-small on any tag
    }
  });

  // Remove empty <p> tags
  sanitizedHtml = removeExtraBreaks(sanitizedHtml);

  return sanitizedHtml;
}, [post?.description, renderTrigger]);


useEffect(() => {
  const fetchPost = async () => {
    if (!postId) return;

    try {
      const postRef = doc(db, "posts", postId);
      const postSnap = await getDoc(postRef);

      if (postSnap.exists()) {
        const updatedPost = { id: postSnap.id, ...postSnap.data() } as Post;
        setPost(updatedPost);
        setEditData(updatedPost); 
      }
    } catch (error) {
      console.error("Error refetching post:", error);
    }
  };

  fetchPost();
}, [renderTrigger, postId]);


// Set the sanitized HTML content directly for mobile devices using innerHTML
// useEffect(() => {
//   if (descriptionRef.current) {
//     descriptionRef.current.innerHTML = isMobileDevice ? sanitizedDescription : "";
//   }
// }, [sanitizedDescription]);

  // Directly set `innerHTML` for mobile rendering using `useEffect`
  useEffect(() => {
    if (/Mobi|Android|iPad|iPhone|iPod/.test(navigator.userAgent)) {
      if (descriptionRef.current) {
        descriptionRef.current.innerHTML = removeExtraBreaks(sanitizedDescription);
      }
    }
  }, [sanitizedDescription]);

  
const postContainerRef = useRef<HTMLDivElement>(null); // Rename to make it clearer

  // Function to capture the post content as an image
//  const captureAndDownloadImage = async () => {
//   // console.log("Button clicked, attempting to capture image.");
//   const element = postContainerRef.current;
//   if (element) {
//     const canvas = await html2canvas(element); // Capture the element as a canvas
//     const imgData = canvas.toDataURL("image/png"); // Convert the canvas to a PNG data URL

//     // Create a link to download the image
//     const link = document.createElement("a");
//     link.href = imgData;
//     link.download = `${post?.title}.png`; // Set the downloaded file name
//     link.click(); // Trigger the download
//   } else {
//     console.log("No element found for postRef.");
//   }
// };

// Function to delete old posts and their associated images
const deleteOldPosts = async () => {
  const now = Date.now(); // Current time in milliseconds
  const twoWeeksInMilliseconds = 14 * 24 * 60 * 60 * 1000; // 14 days in milliseconds

  const categories = ['sell', 'buy', 'to-rent', 'for-rent']; // The categories you want to target

  // Loop through each category to find and delete old posts
  for (let category of categories) {
    // Query posts from the specific category
    const q = query(collection(db, 'posts'), where('category', '==', category));
    const querySnapshot = await getDocs(q);

    // Iterate over the posts
    querySnapshot.forEach(async (doc) => {
      const post = doc.data();

      // Check if the post's `createdAt` is more than two weeks old
      const postDateInMilliseconds = post.createdAt.seconds * 1000; // Convert Firestore Timestamp to milliseconds
      if (now - postDateInMilliseconds > twoWeeksInMilliseconds) {
        // If the post is older than two weeks, delete it along with its images
        await deletePostAndImages(doc.ref, post.imageUrls);
        console.log(`Deleted post ${doc.id} in category ${category}`);
      }
    });
  }
};

// Helper function to delete post and its associated images
const deletePostAndImages = async (postRef, imageUrls) => {
  const storage = getStorage();

  // Delete each image associated with the post
  if (imageUrls && imageUrls.length > 0) {
    const deleteImagePromises = imageUrls.map(async (imageUrl) => {
      const imageRef = ref(storage, imageUrl);
      try {
        await deleteObject(imageRef);
        console.log(`Deleted image: ${imageUrl}`);
      } catch (error) {
        console.error("Error deleting image:", error);
      }
    });
    await Promise.all(deleteImagePromises);
  }

  // Delete the post document from Firestore
  try {
    await deleteDoc(postRef);
    console.log("Post deleted successfully");
  } catch (error) {
    console.error("Error deleting post:", error);
  }
};


// Call the function when the component loads
useEffect(() => {
  deleteOldPosts();
}, []);


// Function to capture and download the post content as an image
const captureAndDownloadImage = async () => {
  const element = postContainerRef.current;

  if (element) {
    domtoimage.toPng(element)
      .then(function (dataUrl) {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `${post?.title}.png`;
        link.click();
      })
      .catch(function (error) {
        console.error("Error capturing post as image:", error);
      });
  } else {
    console.log("No element found for postContainerRef.");
  }
};




// const captureRef = useRef<HTMLDivElement>(null); // Ref for the specific part we want to capture

// // Function to capture the selected content (title, description, and images)
// const captureAndDownloadImage = async () => {
//   const element = captureRef.current; // Access the specific part of the post to capture
//   if (element) {
//     console.log("Capturing content from:", element);

//     // Use html2canvas with the useCORS option set to true
//     html2canvas(element, { useCORS: true })
//       .then((canvas) => {
//         const imgData = canvas.toDataURL("image/png"); // Convert the canvas to an image
//         const link = document.createElement("a");
//         link.href = imgData;
//         link.download = `${post?.title}.png`; // Name the image file after the post's title
//         link.click(); // Trigger the download
//       })
//       .catch((error) => {
//         console.error("Error generating image:", error);
//       });
//   } else {
//     console.log("No content found to capture.");
//   }
// };

const deleteAllImagesFromPost = async (postId) => {
  const postRef = doc(db, "posts", postId);

  try {
      // Retrieve the post document to get current image URLs
      const postSnap = await getDoc(postRef);
      if (!postSnap.exists()) {
          console.log("No such post exists!");
          return;
      }

      const postData = postSnap.data();

      // Check if there are images to delete
      if (postData.imageUrls && postData.imageUrls.length > 0) {
          // Delete each image file from Firebase Storage
          const deletePromises = postData.imageUrls.map(imageUrl => {
              const imageRef = ref(storage, imageUrl);
              return deleteObject(imageRef);
          });

          // Wait for all images to be deleted from storage
          await Promise.all(deletePromises);
          console.log("All images deleted successfully from Firebase Storage");

          // Update the post document to remove all image URLs
          await updateDoc(postRef, {
              imageUrls: []
          });

          console.log("Image URLs cleared from Firestore document");
      } else {
          console.log("No images found for this post.");
      }
  } catch (error) {
      console.error("Error deleting images or updating the post:", error);
  }
};

 
  
  useEffect(() => {
    const fetchPost = async () => {
      try {
        if (!postId) {
          throw new Error("Post ID is undefined");
        }

        const postRef = doc(db, "posts", postId);
        const postSnap = await getDoc(postRef);

        if (!postSnap.exists()) {
          throw new Error("No such document!");
        }

        const postData = { id: postSnap.id, ...postSnap.data() } as Post;
        setPost(postData);
        setEditData(postData);
        setCategory(postData.category);

        console.log('postUserId: ',post?.userId)

        // Increment views
        await updateDoc(postRef, { views: (postData.views || 0) + 1 });
        console.log("Views updated:", postData.views + 1);

        // Check if the post is already collected by the user
        if (user) {
          const collectionRef = doc(db, "collections", `${user.uid}_${postData.id}`);
          const collectionSnap = await getDoc(collectionRef);
          if (collectionSnap.exists()) {
            setIsCollected(true); // The post is already in the user's collection
          }

          // Check if the user is an admin
          const idTokenResult = await user.getIdTokenResult();
          setIsAdmin(!!idTokenResult.claims.admin); // Check if the user has admin privileges
        }
      } catch (error) {
        console.error("Error fetching post:", error.message);
      }
    };

    fetchPost();
  }, [postId, user]);

  if (!post) return <p>Loading...</p>;

  // Convert Firestore Timestamp to Date
  const formatDate = (timestamp: { seconds: number; nanoseconds: number }) => {
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleString();
  };

  // Handle toggle collection status
  const handleToggleCollection = async () => {
    if (!user || !post) return;

    const collectionRef = doc(db, "collections", `${user.uid}_${post.id}`);

    try {
      if (isCollected) {
        // Remove from collection and decrement collections count
        await deleteDoc(collectionRef);
        setIsCollected(false); // Update UI state
        await updateDoc(doc(db, "posts", postId), {
          collections: Math.max((post?.collections || 0) - 1, 0), // Ensure collections is never less than 0
        });
      } else {
        // Add to collection and increment collections count
        await setDoc(collectionRef, {
          userId: user.uid,  // Set the userId when adding to the collection
          postId: post.id,
          addedAt: new Date(),
        });
        setIsCollected(true); // Update UI state
        await updateDoc(doc(db, "posts", postId), { collections: (post?.collections || 0) + 1 });
      }
    } catch (error) {
      console.error("Error toggling collection:", error);
    }
  };

  // Handle edit mode toggle
  // const handleEditToggle = () => {
  //   setIsEditing(!isEditing);
  //   setErrorMessage(null);
  // };
  const handleEditToggle = () => {
    if (!isEditing && post) {
      setEditData({
        ...post,
        captions: post.captions || [] // Ensure captions are copied
      });
    }
    setIsEditing(!isEditing);
  };

  // Handle input changes during edit
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (editData) {
      setEditData({
        ...editData,
        [e.target.name]: e.target.value,
      });
    }
  };


  const resizeImage = (file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target?.result as string;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const MAX_SIZE = 1024;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_SIZE) {
              height = Math.floor((height * MAX_SIZE) / width);
              width = MAX_SIZE;
            }
          } else {
            if (height > MAX_SIZE) {
              width = Math.floor((width * MAX_SIZE) / height);
              height = MAX_SIZE;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx?.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              resolve(resizedFile);
            } else {
              reject(new Error("Failed to resize image"));
            }
          }, file.type);
        };
      };
      reader.readAsDataURL(file);
    });
  };

  

  // const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const selectedFiles = e.target.files ? Array.from(e.target.files) : [];
  //   const MAX_SIZE_IN_BYTES = 1024 * 1024; // 1MB in bytes
  
  //   const processFile = async (file: File): Promise<File | null> => {
  //     const validFileTypes = [
  //       "image/jpeg",
  //       "image/png",
  //       "image/gif",
  //       "image/heic", // Only supported in Safari and certain browsers
  //       "image/heif", // Only supported in Safari and certain browsers
  //       "image/bmp",
  //       "image/tiff",
  //       "image/webp" // Add this as webp is widely supported
  //     ];
      
  
  //     if (!validFileTypes.includes(file.type)) {
  //       setErrorMessage(`Invalid file type: ${file.name}`);
  //       return null;
  //     }
  
  //     if (file.size > MAX_SIZE_IN_BYTES) {
  //       try {
  //         const resizedFile = await resizeImage(file);
  //         if (!resizedFile) {
  //           setErrorMessage(`Unable to resize ${file.name} to under 1MB.`);
  //           return null;
  //         }
  //         return resizedFile;
  //       } catch (error) {
  //         setErrorMessage(`Error resizing image: ${file.name}`);
  //         return null;
  //       }
  //     }
  
  //     return file; // Return original file if it's already below 1MB
  //   };

    
  
  //   Promise.all(selectedFiles.map(processFile))
  //     .then((processedFiles) => {
  //       const validFiles = processedFiles.filter((file): file is File => file !== null);
  //       setNewImages([...newImages, ...validFiles]);
  
  //       // Generate preview URLs for each selected image
  //       const newPreviews = validFiles.map((file) => URL.createObjectURL(file));
  //       setImagePreviews([...imagePreviews, ...newPreviews]);
  
  //       // Add empty captions for new images
  //       setCaptions((prev) => [...prev, ...new Array(validFiles.length).fill("")]);
  //     })
  //     .catch((error) => {
  //       console.error("Error processing files: ", error);
  //       setErrorMessage("Error processing images.");
  //     });
  // };
  
  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files ? Array.from(e.target.files) : [];
    const MAX_SIZE_IN_BYTES = 1024 * 1024; // 1MB in bytes
    
    const processFile = async (file: File): Promise<File | null> => {
      const validFileTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/heic", 
        "image/heif", 
        "image/bmp",
        "image/tiff",
        "image/webp" 
      ];
  
      if (!validFileTypes.includes(file.type)) {
        setErrorMessage(`Invalid file type: ${file.name}`);
        return null;
      }
  
      if (file.size > MAX_SIZE_IN_BYTES) {
        try {
          const resizedFile = await resizeImage(file);
          if (!resizedFile) {
            setErrorMessage(`Unable to resize ${file.name} to under 1MB.`);
            return null;
          }
          return resizedFile;
        } catch (error) {
          setErrorMessage(`Error resizing image: ${file.name}`);
          return null;
        }
      }
      return file;
    };
  
    try {
      const processedFiles = await Promise.all(selectedFiles.map(processFile));
      const validFiles = processedFiles.filter((file): file is File => file !== null);
  
      // Upload images to Firebase and get URLs
      // const uploadPromises = validFiles.map((file) => handleImageUpload(file));
      // const urls = await Promise.all(uploadPromises); 
  
      // setEditData((prevData) => {
      //   if (!prevData) return prevData; 
      //   return {
      //     ...prevData,
      //     imageUrls: [...(prevData.imageUrls || []), ...urls], 
      //   };
      // });

      console.log('updated editdata when uploaded: ', editData?.imageUrls)
      
      const newPreviews = validFiles.map((file) => URL.createObjectURL(file));
      setImagePreviews([...imagePreviews, ...newPreviews]);
      setNewImages([...newImages, ...validFiles]);
  
      // Update state to store previews and initialize captions for each new image
      console.log('previous caption:', captions);
      // setImagePreviews([...imagePreviews, ...validFiles.map((file) => URL.createObjectURL(file))]);
      // setImagePreviews((prev) => [...prev, ...validFiles.map((file) => URL.createObjectURL(file))]);
      // setCaptions((prev) => [...prev, ...new Array(validFiles.length).fill("")]); // Add empty captions
      setNewCaptions(newCaptions => [...newCaptions, ...new Array(validFiles.length).fill('')]);
    
    
    } catch (error) {
      console.error("Error processing files: ", error);
      setErrorMessage("Error processing images.");
    }
  };
  


  // Handle image selection and generate previews old data
  // const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files) {
  //     const selectedFiles = Array.from(e.target.files);
  //     const largeFiles = selectedFiles.filter(file => file.size > 1024 * 1024); // Check if any files are larger than 1MB

  //     if (largeFiles.length > 0) {
  //       setErrorMessage(`File ${largeFiles[0].name} is too large. Please upload files smaller than 1MB.`);
  //     } else {
  //       setErrorMessage(null);
  //       setNewImages([...newImages, ...selectedFiles]);

  //       // Generate preview URLs for each selected image
  //       const newPreviews = selectedFiles.map((file) => URL.createObjectURL(file));
  //       setImagePreviews([...imagePreviews, ...newPreviews]);

  //            // Add an empty caption for each new image
  //       setCaptions((prev) => [...prev, ...new Array(selectedFiles.length).fill("")]);
   
  //     }
  //   }
  // };

  // Handle image uploads to Firebase Storage
  const handleImageUpload = async (imageFile: File): Promise<string> => {
    const storageRef = ref(storage, `posts/${postId}/${imageFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, imageFile);
    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        () => {},
        (error) => reject(error),
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };

const handleDeleteAllImages = async () =>  {
  if (deleteAllImages) {
    await deleteAllImagesFromPost(postId);
}
}

  // Handle form submission (edit save)
  const handleSubmit = async () => {
    if (!editData) return;
 

    const newImageUrls = await Promise.all(newImages.map((image) => handleImageUpload(image)));
    const updatedImageUrls = [...(editData?.imageUrls || []), ...newImageUrls];
    const keywords
     = editData.keywords || editData.title.split("").map(char => char.trim()).filter(char => char !== "");
      // Combine existing captions with new captions
    
    console.log('caption at submitted: ', editData.captions)
    console.log('new captions at submitted: ', newCaptions);


      // const updatedCaptions = [...captions, ...newCaptions];
      const updatedCaptions = [...captions, ...newCaptions];
      // const updatedCaptions = [...captions, ...newCaptions].map((caption) => caption ?? null);
      console.log('updated captions at submitted: ', updatedCaptions );
      console.log('the original captions are: ', post?.captions);
  
    try {
       // Prepare the updated data for Firestore update
       const updatedData = {
        title: editData.title,
        description: editData.description,
        category: category,
        imageUrls: updatedImageUrls,
        captions: updatedCaptions,
        keywords: keywords,
        };

    // Firestore document reference
    const postRef = doc(db, "posts", postId);

    // Update Firestore document
    await updateDoc(postRef, updatedData);
   

    // Update local state with the same data used for Firestore to keep them in sync
    const updatedPost = { ...editData, ...updatedData};
    
    setPost(updatedPost);
    setEditData(updatedPost);

    setIsEditing(false);
    setNewImages([]);
    setImagePreviews([]);
    setErrorMessage(null);

    // setCaptions([]);
    // setNewCaptions([]);


      setRenderTrigger((prev) => !prev); // Force a re-render
    } catch (error) {
      console.error("Error updating document:", error);
    }

    
  };



  

   // Quill toolbar modules with table options
 const modules = {
  toolbar: [
    // [{ header: [1, 2, 3] }],
    [{ size: ["small", false, "large"] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    [{ table: "insert-table" }],
    [
      { table: "insert-row-above" },
      { table: "insert-row-below" },
      { table: "delete-row" },
      { table: "insert-column-right" },
      { table: "insert-column-left" },
      { table: "delete-column" },
    ],
    [{ table: "delete-table" }],
  ],
  clipboard: {
    matchVisual: false, // Disable visual matching to prevent extra <p><br></p> tags
  },

};




  // Handle preview toggle
  // const handlePreviewToggle = () => {
  //   setIsPreviewing(!isPreviewing);
  // };
  // Function to handle preview toggle and clean up description HTML in preview mode
// const handlePreviewToggle = () => {
//   if (!isPreviewing) {
//     // Clean up extra <br> tags in the description for preview mode
//     setEditData((prevData) => prevData ? {
//       ...prevData,
//       description: removeExtraBreaks(prevData.description),
//     } : prevData);
//   }
//   setIsPreviewing(!isPreviewing);
// };

// const handlePreviewToggle = () => {
//   // Ensure latest captions are used in preview
//   if (!isPreviewing) {
//       setEditData(prevData => {
//           if (prevData) {
//               return {
//                   ...prevData,
//                   captions: [...captions, ...newCaptions] // Combine existing and new captions
//               };
//           }
//           return prevData;
//       });
//   }
//   setIsPreviewing(!isPreviewing);
// };

// const handlePreviewToggle = () => {
//   if (!isPreviewing) {
//     // Merge existing captions from editData with any new captions
//     setEditData((prevData) => {
//       if (prevData) {
//         const mergedCaptions = [
//           ...prevData.captions, // Existing captions
//           ...newCaptions.filter((caption, index) => index >= prevData.captions.length) // Add only new captions
//         ];
//         console.log('previous data: ', prevData);
//         return {
//           ...prevData,
//           captions: mergedCaptions,
//         };
//       }
//       return prevData;
//     });
//   }
//   setIsPreviewing(!isPreviewing);
// };

const handlePreviewToggle = () => {
  if (!isPreviewing) {
    setEditData((prevData) => {
      if (prevData) {
        const mergedCaptions = [
          ...prevData.captions, // Keep original captions
          ...newCaptions.filter((caption) => caption) // Add only non-empty new captions
        ];
        return {
          ...prevData,
          captions: mergedCaptions.length > 0 ? mergedCaptions : prevData.captions, // Only update if there are new captions
        };
      }
      return prevData;
    });
  }
  setIsPreviewing(!isPreviewing);
};


// Handle image deletion
      // const handleDeleteImage = async (imageUrl: string) => {
      //   // Find the index of the image URL to be deleted
      //   const imageIndex = editData?.imageUrls.findIndex((url) => url === imageUrl);

      //   // Update the image URLs by removing the selected image
      //   const updatedImageUrls = editData?.imageUrls.filter((url) => url !== imageUrl);

      //   // Update the captions by removing the caption at the same index as the deleted image
      //   const updatedCaptions = captions.filter((_, index) => index !== imageIndex);

      //   // Update the state with new image URLs and captions
      //   setEditData((prevData) => prevData ? { ...prevData, imageUrls: updatedImageUrls || [] } : null);
      //   setCaptions(updatedCaptions); // Update the captions state

      //   const imageRef = ref(storage, imageUrl);
      //   try {
      //     await deleteObject(imageRef);
      //     console.log(`Deleted image: ${imageUrl}`);
      //   } catch (error) {
      //     console.error("Error deleting image:", error);
      //   }
      // };

      // const handleDeleteImage = async (index) => {
      //   if (!editData) return;
      
      //   // Remove the URL from `imageUrls` and delete the file from Firebase Storage
      //   const imageUrlToDelete = editData.imageUrls[index];
      //   const updatedImageUrls = editData.imageUrls.filter((_, i) => i !== index);
      
      //   // Update the `captions` array by removing the caption at the specific index
      //   const updatedCaptions = captions.filter((_, i) => i !== index);
      
      //   // Update `editData` and `captions` state to reflect changes
      //   setEditData((prevData) => (prevData ? { ...prevData, imageUrls: updatedImageUrls } : prevData));
      //   setCaptions(updatedCaptions);
      
      //   // Delete the image file from Firebase Storage
      //   const storageRef = ref(storage, imageUrlToDelete);
      //   try {
      //     await deleteObject(storageRef);
      //     console.log(`Deleted image: ${imageUrlToDelete}`);
      //   } catch (error) {
      //     console.error("Error deleting image from storage:", error);
      //   }
      
      //   // Update Firestore with the new `imageUrls` array
      //   try {
      //     const postRef = doc(db, "posts", postId);
      //     await updateDoc(postRef, { imageUrls: updatedImageUrls, captions: updatedCaptions });
      //     console.log("Firestore post updated successfully");
      //   } catch (error) {
      //     console.error("Error updating Firestore document:", error);
      //   }
      // };

      // const handleDeleteImage = async (imageUrl) => {
      //   if (!editData || !postId) return;
      //   console.log('caption before deleted: ', captions)
      
      //   // Find the index of the image to delete
      //   const imageIndex = editData.imageUrls.findIndex((url) => url === imageUrl);      
      //   // Remove the image URL from the array
      //   const updatedImageUrls = editData.imageUrls.filter((url, index) => index !== imageIndex);
      
      //   // Remove the corresponding caption from the captions array
      //   const updatedCaptions = captions.filter((caption, index) => index !== imageIndex);
      
      //   // Update Firestore with the new arrays
      //   const postRef = doc(db, "posts", postId);
      //   try {
      //     await updateDoc(postRef, {
      //       imageUrls: updatedImageUrls,
      //       captions: updatedCaptions, // Update captions array in Firestore
      //     });
      
      //     // Update the local state to reflect the change
      //     setEditData((prevData) => prevData ? { ...prevData, imageUrls: updatedImageUrls } : prevData);
      //     setCaptions(updatedCaptions); // Update captions state locally as well

      //     console.log('caption after deleted: ', captions)
      
      //     // Delete the image from Firebase Storage
      //     const imageRef = ref(storage, imageUrl);
      //     await deleteObject(imageRef);
      //     console.log(`Deleted image: ${imageUrl}`);
      //   } catch (error) {
      //     console.error("Error deleting image and updating captions:", error);
      //   }
      // };

      const handleDeleteImage = async (imageUrl) => {
        if (!editData || !postId) return;
        console.log('Caption before deleted:', captions);
    
        // Find the index in existing saved images
        const imageIndex = editData.imageUrls.findIndex((url) => url === imageUrl);

        console.log('deleted imageIndex', imageIndex);
    
        if (imageIndex !== -1) {
            // Image is from the saved images
            const updatedImageUrls = editData.imageUrls.filter((_, index) => index !== imageIndex);
            const updatedCaptions = captions.filter((_, index) => index !== imageIndex);

            console.log('updatedcaption after deletion', updatedCaptions);
            console.log('updated url after deletion', updatedImageUrls);
    
            try {
                // Update Firestore with the new arrays
                const postRef = doc(db, "posts", postId);
                await updateDoc(postRef, {
                    imageUrls: updatedImageUrls,
                    captions: updatedCaptions,
                });
    
                // Update local state
                setEditData((prevData) => prevData ? { ...prevData, imageUrls: updatedImageUrls } : prevData);
                setCaptions(updatedCaptions);
    
                // Delete the image from Firebase Storage
                const imageRef = ref(storage, imageUrl);
                await deleteObject(imageRef);
                console.log('Deleted image:', imageUrl);
            } catch (error) {
                console.error("Error deleting image and updating captions:", error);
            }
        } else {
            // Image is a new upload and not yet saved
            const newImageIndex = newImages.findIndex(image => URL.createObjectURL(image) === imageUrl);
            console.log('deleted not stored post imgurl: ', newImageIndex);
            
            if (newImageIndex !== -1) {
                const updatedNewImages = newImages.filter((_, index) => index !== newImageIndex);
                const updatedNewCaptions = newCaptions.filter((_, index) => index !== newImageIndex);
    
                console.log('updated Newcaption after deletion', updatedNewCaptions);
                // console.log('updated NewImage after deletion', updatedNewImages);
                
                setNewImages(updatedNewImages);
                setNewCaptions(updatedNewCaptions);
                // Optionally revoke the object URL to clean up resources
                URL.revokeObjectURL(imageUrl);
            }
        }
    
        console.log('Caption after deleted:', captions);
    };
    


  // Handle preview image deletion
  const handleDeletePreviewImage = (index: number) => {
    const updatedPreviews = imagePreviews.filter((_, i) => i !== index);
    const updatedNewImages = newImages.filter((_, i) => i !== index);
    setImagePreviews(updatedPreviews);
    setNewImages(updatedNewImages);
  };

  // Handle cancel
  
  const handleCancel = () => {
    setIsEditing(false);
    setEditData(post);
    setNewImages([]);
    setImagePreviews([]);
    setErrorMessage(null);
  };


  const handleBackClick = () => {
    // navigate(`/category/${post.category}`); 
    navigate(-1);
  };

  // const handleDeletePost = async () => {
  //   if (!postId) return;

  //   const postRef = doc(db, "posts", postId);

  //   // First, delete images associated with the post
  //   if (post.imageUrls && post.imageUrls.length > 0) {
  //     const deleteImagePromises = post.imageUrls.map(async (imageUrl) => {
  //       const imageRef = ref(storage, imageUrl);
  //       try {
  //         await deleteObject(imageRef);
  //       } catch (error) {
  //         console.error("Error deleting image:", error);
  //       }
  //     });
  //     await Promise.all(deleteImagePromises);
  //   }
  //       // Delete the post from Firestore
  //       try {
  //         // await deleteDoc(postRef);
  //         await updateDoc(postRef, {
  //           deleted: true,
  //           deletedAt: new Date(), // Add the deletion timestamp
  //         });
  //         console.log("Post deleted successfully");
  //         navigate("/"); // Redirect the user after deleting the post
  //       } catch (error) {
  //         console.error("Error deleting post:", error);
  //       }
  //     };
  const handleDeletePost = async () => {
    if (!postId) return; // Ensure postId is defined

    const postRef = doc(db, "posts", postId); // Reference to the specific post document

    try {
        // Mark post as deleted without deleting the document or images
        await updateDoc(postRef, {
            deleted: true,           // Set the deleted flag to true
            deletedAt: new Date(),  // Optionally record when it was marked as deleted
        });

        // Update local state to reflect the post is deleted
        setIsDeleted(true); // Change the state to trigger a re-render

        console.log("Post marked as deleted successfully");

        // Optionally, navigate or update the UI as needed
        navigate("/"); // Redirect the user after marking the post as deleted
    } catch (error) {
        console.error("Error marking post as deleted:", error); // Log any errors that occur
    }
};



      // Handle caption input for new images
      // const handleNewCaptionChange = (index: number, caption: string) => {
      //   const updatedCaptions = [...newCaptions];
      //   updatedCaptions[index] = caption;
      //   setNewCaptions(updatedCaptions);
      // };

    


      const handleCaptionChange = (index: number, caption: string) => {
        const updatedCaptions = [...captions];
        updatedCaptions[index] = caption; // Update the caption at the correct index
        setCaptions(updatedCaptions);
      };
      
      // For new images
        const handleNewCaptionChange = (index, caption) => {
          const updatedNewCaptions = [...newCaptions];
          updatedNewCaptions[index] = caption;
          console.log("Updating new captions at index:", index, "with caption:", caption);
          console.log("Before update:", newCaptions);
          console.log("After update:", updatedNewCaptions);
          setNewCaptions(updatedNewCaptions);
        };



      // Real-time handling of content changes in ReactQuill
    // const handleEditorChange = (value) => {
    //   const cleanedValue = removeExtraBreaks(value); // Clean up unnecessary <br> tags
    //   setEditData((prev) => (prev ? { ...prev, description: cleanedValue } : null));
    // };



    
  return (
    <div className="post-detail">

      {/* Use Helmet to set dynamic meta tags for the post */}
      <Helmet>
        <title>{post.title} | AZ Life</title>
        <meta name="description" content={post.description} />
        <meta property="og:title" content={post.title} />
        {/* <meta property="og:description" content={post.description} /> */}
        <meta property="og:description" content={post.description.replace(/<[^>]+>/g, '')} /> 
        <meta property="og:image" content={post.imageUrls[0]} /> {/* First image as OG image */}
        <meta property="og:url" content={`https://azlife168.com/post/${post.category}/${post.id}`} />
        <meta property="og:type" content="article" />
      </Helmet>
    
      <div className="header-post">
        <Navibar />
      </div>

      <div className="post-container" >
        {isPreviewing ? (
          // Preview Mode
          <div className="preview-mode">
                

                <div>
                  <h1>{editData?.title}</h1>
                  

                    <p className='preview-header' style={{ whiteSpace: "nowrap" }}>
                      用户: {post?.username} | 发帖时间: {formatDate(post?.createdAt)} | 类别: <span>{category.toLowerCase()}</span>
                    </p>
                    {/* <p className="description">{editData?.description}</p>
                     */}
                     <div
                        className="description"
                        dangerouslySetInnerHTML={{ __html: editData?.description || "" }}
                      ></div>

                      {/* <div
                      className="description"
                      dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                    ></div>
                </div> */}

                </div>

                <div>

                {/* Show Existing Images */}
                {editData?.imageUrls && editData.imageUrls.length > 0 && (
                  <div className="post-images">
                    {/* <h3>Existing Images:</h3> */}
                    {editData.imageUrls.map((url, index) => (
                      <div key={index}>
                        <img src={url} alt={`Post Image ${index}`} className="existing-image" />
                        <p className="caption-input">
                          {captions[index] !== undefined ? captions[index] : (editData.captions && editData.captions[index] ? editData.captions[index] : "No caption provided")}
                        </p>
                      </div>
                    ))}
                  </div>
                )}

              {/* Show New Image Previews */}
                {/* {imagePreviews.length > 0 && (
                      <div className="post-images">
                        {imagePreviews.map((preview, index) => (
                          <div key={index}>
                            <img src={preview} alt={`Preview ${index}`} className="preview-image" />
                             <p className="fig-caption" style={{textAlign: "left"}}>{captions[(editData?.imageUrls?.length || 0) + index] ? captions[(editData?.imageUrls?.length || 0) + index] : "No caption provided"}</p>
                          </div>
                        ))}
                      </div>
                )} */}
                {imagePreviews.length > 0 && (
                    <div className="post-images">
                      {imagePreviews.map((preview, index) => (
                        <div key={index}>
                          <img src={preview} alt={`Preview ${index}`} className="preview-image" />
                          <p className="fig-caption" style={{textAlign: "left"}}>
                            {newCaptions[index] ? newCaptions[index] : "No caption provided"}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}


                </div>

              <button onClick={handlePreviewToggle} className="back_edit">返回到编辑页面</button>
        </div>
      ) : (
          <>
            {isEditing ? (
              // Edit Mode
              <div className="edit-mode">
                      
                      <div>
                        <input
                          type="text"
                          name="title"
                          value={editData?.title}
                          onChange={handleInputChange}
                        />
                      </div>

                      {/* <div>
                            <textarea
                              name="description"
                              value={editData?.description}
                              onChange={handleInputChange}
                              rows={10}  // Added for multi-line support
                              cols={80}  // Added to control the width of the textarea
                            />
                      </div> */}

                      <div className="ql-wrapper">
                          {/* Replace textarea with ReactQuill */}
                          <ReactQuill
                              value={editData?.description || ""}
                              // onChange={handleInputChange}
                              onChange={(value) => setEditData(prev => prev ? {...prev, description: value} : null)}
                              // onChange={handleEditorChange}
                              theme="snow" // Optional: sets the Quill editor theme
                              modules={modules}
                              className="edit-description"   
                          />
                      </div>
                    
                    <div className="category-option">
                          {/* Category Selector */}
                          <select value={category} onChange={(e) => setCategory(e.target.value)}>
                            {/* {isAdmin && <option value="hotposts">精华帖</option>}
                            {isAdmin && <option value="marketing">商业推广</option>}
                            <option value="sell">出售</option>
                            <option value="buy">求购</option>
                            <option value="for-rent">出租/招租</option>
                            <option value="to-rent">求租</option>
                            <option value="complain">我要吐槽</option>
                            <option value="others">其他</option> */}
                              {generateCategoryOptions(isAdmin)};
                          </select>
                    </div>

                    <div>
                      <input type="file" multiple onChange={handleImageChange}
                       accept="image/jpeg, image/png, image/gif, image/heic, image/heif, image/bmp, image/tiff, image/x-tiff" />
                    </div>

                {/* Show existing images and make captions editable */}
                {editData?.imageUrls && editData.imageUrls.length > 0 && (
                  <div className="post-images">
                    {editData.imageUrls.map((url, index) => (
                      <div key={index}>

                          <img src={url} alt={`Post Image ${index + 1}`} style={{ width: "150px", margin: "0px" }} />
                          <button onClick={() => handleDeleteImage(url)} className="image-del-btn">Delete</button>
                         

                          {/* Editable caption input for old images */}
                          <div>
                                  <input
                                    type="text"
                                    // value={captions[index] || editData?.captions[index]}  
                                    value={captions[index] !== undefined ? captions[index] : editData?.captions[index]}
                                    onChange={(e) => handleCaptionChange(index, e.target.value)}  //{/* Update caption */}
                                    className="caption-input"
                                    placeholder="Edit caption"
                                  />
                            </div>
                      
                      </div>
                    ))}

                  </div>
                )}

{/* Show previews of new images */}
{/* {imagePreviews.length > 0 && (
  <div className="image-previews">
    {imagePreviews.map((preview, index) => (
      <div key={index}>

        <img src={preview} alt={`Preview ${index}`} style={{ width: "150px", margin: "0px" }} />
        <button onClick={() => handleDeletePreviewImage(index)} className="image-del-btn">Delete</button>
        <input
          type="text"
          placeholder="Add caption"
          value={captions[editData?.imageUrls?.length || 0 + index] || ""}  
          onChange={(e) => handleCaptionChange(editData?.imageUrls?.length || 0 + index, e.target.value)}
          className="caption-input"
        />
       
      </div>
    ))}
  </div>
)}
     */}

{imagePreviews.length > 0 && (
  <div className="image-previews">
    {imagePreviews.map((preview, index) => (
      <div key={index}>
        <img src={preview} alt={`Preview ${index}`} style={{ width: "150px", margin: "0px" }} />
        <button onClick={() => handleDeletePreviewImage(index)} className="image-del-btn">Delete</button>
        <input
          type="text"
          placeholder="Add caption"
          value={newCaptions[index] || ""}  // Use newCaptions for handling new image captions
          onChange={(e) => handleNewCaptionChange(index, e.target.value)}
          className="caption-input"
        />
      </div>
    ))}
  </div>
)}


              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

              <div className='btn-group' >

                  <button onClick={handleSubmit} className="submit_btn">保存</button>
                  <button onClick={handlePreviewToggle} className="preview_btn">预览</button>
                  <button onClick={handleCancel} className="cancel_btn">取消</button> {/* Cancel button */}
              </div>
              
              </div>
            ) : (
              // View Mode
              <div>

                  <div ref={postContainerRef} className="view-post">
                      <div className="breadcrumb">
                          <Link to="/">主页</Link>
                          <span className="separator"> › </span>
                          <Link to={`/category/${post.category}`}>{CategoryMap[post.category]}</Link>
                          <span className="separator"> › </span>
                          <Link to={`/category/${post.category}/${post.id}`}>
                              {post.title}
                          </Link>

                          {/* "返回" Button */}
                              {/* <button onClick={() => navigate(-1)} className="return_btn">
                                返回
                              </button> */}
                      </div>
                    
                      {/* <div className="bot-btn">
                        
                        <button onClick={handleBackClick} className="return_btn">返回</button> */}
                        {/* <button onClick={captureAndDownloadImage} className="download-btn">
                            生成图片
                        </button> */}
                      {/* </div> */}


            

                     
                      <h1>{post.title}</h1>

                      <div className="note"> 
                            <p className="first-line" style={{ whiteSpace: "nowrap" }}>
                              用户: <span>{post?.username}</span> | 发帖时间: <span>{formatDate(post?.createdAt)}</span> | 类别: <span>{category.toLowerCase()}</span> 
                            </p>
                            <p className="view-count">
                              Views: <span>{post?.views || 0}</span> | Collections: <span>{post?.collections || 0}</span>
                            </p>
                      </div>

                      {/* <div>
                        <p  className="post-description">{post.description}</p>
                      </div> */}

                         {/* <div dangerouslySetInnerHTML={{ __html: post.description }}></div>
                          */}

                      {/* <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(post.description) }}></div> */}
                      <div
                        ref={descriptionRef}
                        className="post-description"
                        dangerouslySetInnerHTML={!isMobileDevice ? { __html: sanitizedDescription } : undefined}
                      ></div>

                      <div>
                    
                                {/* Show post images */}
                                {post.imageUrls && post.imageUrls.length > 0 && (
                                  // <div className="post-images">
                                  //   {post.imageUrls.map((url, index) => (
                                  //     <img key={index} src={url} alt={`Post Image ${index}`} style={{ width: "500px", margin: "5px" }} />
                                  //   ))}
                                  // </div>
                                  <div className="view-post-images">
                                        {post.imageUrls.map((url, index) => (
                                          <div key={index}>
                                            <img src={url} alt={`Post Image ${index}`} />
                                          {/* Display the corresponding caption */}
                                            <p style={{ fontWeight: "bold", textAlign: "left"}} className="figure-caption">
                                                    {/* {post.captions && post.captions[index] ? post.captions[index] : "No caption provided"} */}
                                                    {post.captions && post.captions[index]}
                                            </p>
                                          </div>
                                        ))}
                                    </div>
                                )}
                        </div>
               



                        {/* Allow editing only if user is the author or an admin */}
                          {/* Allow editing and deleting only if user is the author or an admin */}
                          {(user?.uid === post?.userId || isAdmin) && (
                          <>
                            <button onClick={handleEditToggle} className="edit_btn">编辑</button>
                            <button onClick={handleDeletePost} className="delete_btn">删除</button>
                          </>
                        )}

                        {/* Show collection icon only for non-authors and non-admins */}
                        
                        <div  className="collection-heart">
                            {user && user.uid !== post?.userId && !isAdmin && (
                              <FontAwesomeIcon
                                icon={isCollected ? filledHeart : outlinedHeart}
                                onClick={handleToggleCollection}
                                style={{ cursor: "pointer", color: isCollected ? "red" : "black" }}
                                size="2x"
                              
                              />
                            )}
                        </div>

                      
  
     {/* <button onClick={handleDeleteAllImages}>
        Delete all images
      </button>  */}
   {/* <label>
                <input
                    type="checkbox"
                    checked={deleteAllImages}
                    // checked={handleDeleteAllImages}
                    onChange={e => setDeleteAllImages(e.target.checked)}
                />
                Delete all images
            </label> */}


                       

                        {/* Add comments section */}
                        <div className="view-comments">
                          {/* Call AddComment and pass the postId and category as props */}
                            {user?.uid && <AddComment postuserId={post.userId} postId={post.id} category={post.category} /> }
                        </div>

                        {/* Display existing comments */}
                        <div>
                          <CommentsDisplay postId={post.id} category={post.category} postAuthorId={post.userId}/>
                        </div>

                      </div>
                </div>
            )}
          </>
        )}
      </div>

    </div>
  );
};
