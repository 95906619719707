import './App.css';
import React, { useEffect, useState } from "react";
import { Home } from "./components/Home.tsx";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { SignUp } from './components/signup.tsx';
import { ForgotPassword } from "./components/forgot_password.tsx";
import { LoginWithEmail } from "./components/login_email.tsx";
import { CreatePost } from './pages/create_post.tsx';
import { Posts } from './mian/posts.tsx';
import { PostDetail } from './mian/post.tsx';
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "./config/firebase.ts"; // Adjust the import based on your structure
import { PostPractice } from './mian/posts_practice.tsx';
import { SellPosts } from './mian/category_page.tsx';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import { AdminDashboard } from './admin/admin-dashboard.tsx';
// import { NotificationsPage } from './components/notificationspage.tsx';
import {CategoryPosts} from "./pages/category_posts.tsx"
import { MyPosts } from './pages/myposts.tsx';
import { MyCollections } from './pages/mycollections.tsx';
import { SearchResults } from './pages/searchresults.tsx';
import { BlockedUserProvider } from './admin/blockedusercontext.tsx';
import { ReviewPosts } from './admin/review_posts.tsx';
import { ReadMe } from './pages/readne.tsx';
import { Helmet } from 'react-helmet';
import {DeletedPosts} from './admin/deletedposts.tsx';




// Define the Post interface
interface Post {
  id: string;
  title: string;
  description: string;
  category: string;
  username: string | undefined;
  imageUrls: string[]; // Ensure this is an array
}

function App() {
  const [posts, setPosts] = useState<Post[]>([]); // Initialize as an empty array of type Post[]
  const [user] = useAuthState(getAuth());
  const [isAdmin, setIsAdmin] = useState(false);

  const checkAdmin = async () => {
    if (user) {
      const idTokenResult = await user.getIdTokenResult();
      return !!idTokenResult.claims.admin;
    }
    return false;
  };


// Check if the user is an admin
useEffect(() => {
  const fetchAdminStatus = async () => {
    const adminStatus = await checkAdmin();
    setIsAdmin(adminStatus);
  };

  fetchAdminStatus();
}, [user]);


  useEffect(() => {

    
    const postsRef = collection(db, "posts");

    const unsubscribe = onSnapshot(postsRef, (snapshot) => {
      const fetchedPosts: Post[] = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })) as Post[]; // Ensure fetchedPosts is typed as Post[]

      setPosts(fetchedPosts);
      // console.log(fetchedPosts);
    });

    return () => unsubscribe();
  }, []);



  return (
    <div className="App">

    <Helmet>
        <title>亚利桑那生活网</title>
        <meta name="description" content="让Arizona的生活变得丰富多彩。" />
      </Helmet>
  
       
   
  
      <Router>
      <BlockedUserProvider>
     
        <Routes>
        <Route
          path="/admin_post"
          element={
            isAdmin ? <AdminDashboard /> : <Navigate to="/" />
          }
        />
          
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/signinwithemail" element={<LoginWithEmail />} />
          <Route path="/createpost" element={<CreatePost />} />
          <Route path="/allposts" element={<PostPractice />} />

          <Route path="/myposts" element={<MyPosts />}/>
          <Route path="/mycollections" element={<MyCollections />}/>
          <Route path="/readme" element={<ReadMe />} />

          <Route path="/search" element={<SearchResults />} />

          <Route path='/postreviews' element = {<ReviewPosts />} />
          <Route path='/deletedposts' element = {<DeletedPosts />} />
    

          {/* <Route path="/category/sell" element={<SellPosts />} /> */}

          {/* <Route path="/category/:category" element={<Posts posts={posts} category="hotposts" category_name="精华帖" />} />
          <Route path="/category/:category" element={<Posts posts={posts} category="marketing" category_name="市场推广" />} />
          <Route path="/category/:category" element={<Posts posts={posts} category="sell" category_name="出售" />} />
          <Route path="/category/:category" element={<Posts posts={posts} category="buy" category_name="求购/求助" />} />
          <Route path="/category/:category" element={<Posts posts={posts} category="for-rent" category_name="出租/招租" />} />
          <Route path="/category/:category" element={<Posts posts={posts} category="to-rent" category_name="求租" />} /> */}

          <Route path="/category/hotposts" element={<CategoryPosts category="hotposts" isAdmin={isAdmin}/>} />
          <Route path="/category/marketing" element={<CategoryPosts category="marketing" isAdmin={isAdmin}/>} />
          <Route path="/category/share" element={<CategoryPosts category="share" isAdmin={isAdmin}/>} />
          <Route path="/category/sell" element={<CategoryPosts category="sell" isAdmin={isAdmin}/>} />
          <Route path="/category/buy" element={<CategoryPosts category="buy" isAdmin={isAdmin}/>} />
          <Route path="/category/for-rent" element={<CategoryPosts category="for-rent" isAdmin={isAdmin}/>} />
          <Route path="/category/to-rent" element={<CategoryPosts category="to-rent" isAdmin={isAdmin}/>} />
          <Route path="/category/complain" element={<CategoryPosts category="complain" isAdmin={isAdmin}/>} />
          <Route path="/category/others" element={<CategoryPosts category="others" isAdmin={isAdmin}/>} />
          

          
          <Route path="/category/:category/:postId" element={<PostDetail />} />

          {/* <Route path="/notifications" element={<NotificationsPage />} /> */}

          <Route path="/admin" element={<AdminDashboard />}/>

        </Routes>
        </BlockedUserProvider>
      </Router>
    </div>
  );
}

export default App;

