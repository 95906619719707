import React from "react";

export const CategoryMap: { [key: string]: [string, string] } = {
    hotposts: ['精华帖', '🔥'],
    share: ['我要分享', '💡'],
    sell: ['出售', '💰'],
    buy: ['求购', '🛒'],
    'for-rent': ['出租/招租', '🏠'],
    'to-rent': ['求租', '🔑'],
    complain: ['我要吐槽', '🙄'],
    others: ['其他', '✨']
  };
  

// Function to find the key by the 'name' value
export const findKeyByCategoryName = (categoryName: string): string | null => {
  // Iterate through each key-value pair in the CategoryMap
  for (const [key, [name] ] of Object.entries(CategoryMap)) {
    if (name === categoryName) {
      return key; // Return the key when the name matches
    }
  }
  return null; // Return null if no match is found
};

// Function to check if an option should be shown based on admin status
export const shouldShowOption = (key: string, isAdmin: boolean): boolean => {
  // Only show 'hotposts' and 'marketing' if the user is an admin
  if (!isAdmin && (key === 'hotposts' || key === 'marketing')) {
    return false;
  }
  return true;
};

// Refactor to loop through CategoryMap and dynamically generate <option> tags
export const generateCategoryOptions = (isAdmin: boolean): JSX.Element[] => {
  return Object.entries(CategoryMap)
    .map(([key, [name, icon]]) => {
      if (shouldShowOption(key, isAdmin)) {
        return (
          <option key={key} value={key}>
            {icon} {name}
          </option>
        );
      }
      return null; // Return null if option should not be rendered
    })
    .filter((option): option is JSX.Element => option !== null); // Filter out null entries
};
