import { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../config/firebase.ts';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/AZ LIFE2.png'
import "./forgotpassword.css"

export const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const handlePasswordReset = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);
        setMessage(null);

        try {
            await sendPasswordResetEmail(auth, email);
            setMessage("Password reset email sent! Check your inbox.");
        } catch (error) {
            setError('Failed to send password reset email.');
        }
    };

    return (
        <div className='container'>

            <div className="logo_password">
                <Link to='/'>
                    <img src={logo}  width="150px"/>
                </Link>
                    
        </div>
           
            <form onSubmit={handlePasswordReset} className='forgot-pwd'>
                <h2>Forgot Password</h2>
                {message && <p style={{ color: 'green' }}>{message}</p>}
                {error && <p style={{ color: 'red' }}>{error}</p>}
                
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className='email-address'
                    required
                />
                <button type="submit" className="reset-pwd">Reset Password</button>
            </form>
        </div>
    );
};
