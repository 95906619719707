// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from 'firebase/auth'
import {getFirestore} from "firebase/firestore"

// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBBD60tKSeFnU_3nJFy8Ez1KiJzngKMIjE",
  authDomain: "azlife168-4f00f.firebaseapp.com",
  projectId: "azlife168-4f00f",
  storageBucket: "azlife168-4f00f.appspot.com",
  messagingSenderId: "200259687720",
  appId: "1:200259687720:web:626cccd26a4650fc916c1d",
  measurementId: "G-JWDGVS5SL2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);
// Initialize Firebase Storage
export const storage = getStorage(app);