import React from "react"
import { Link } from "react-router-dom"
import "./footer.css"
import azLifeLogo from '../images/AZ LIFE2.png'; 


export default function Footer() {
    return (

        <>
           <div className="footer-wrapper">
            <div className="footer">
                <div className="website_title">
                    <img src={azLifeLogo} alt="AZ LIFE Logo"  width="60px"/>

                    <h1> AZ生活网</h1>

                </div>
              
                <h3>
                {/* <Link to="/marketing">商务合作</Link> */}
                </h3>
                <p>客服微信: fajitas130</p>
                <p>微信公众号/小红书：AZ生活网</p>
                <p>Email: azlife168@gmail.com</p>
               
            </div>

        </div> 
        
        </>
        
       
        
    )
}