import React, { useState } from "react";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../config/firebase.ts"; // Adjust the import based on your structure
import "./editable-post.css"
import DOMPurify from "dompurify"; // Ensure DOMPurify is imported
import ReactQuill from "react-quill";


interface Post {
  imageUrls: string[];
  username: string;
  id: string;
  title: string;
  description: string;
  category: string;
  approved: boolean;
  userId: string; // User ID of the author for notifications
  rejected: boolean;
}

interface EditablePostProps {
  post: Post;
  onUpdate: () => void; // Callback to refresh the posts list
}

export const EditablePost: React.FC<EditablePostProps> = ({ post, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(post.title);
  const [description, setDescription] = useState(post.description);
  const [category, setCategory] = useState(post.category);
  const [rejectionReason, setRejectionReason] = useState("");

  // Function to save changes
  const saveChanges = async () => {
    const postRef = doc(db, "posts", post.id);
    try {
      await updateDoc(postRef, {
        title: title,
        description: description,
        category: category,
      });
      setIsEditing(false); // Exit editing mode
      onUpdate(); // Refresh the posts list
    } catch (error) {
      console.error("Error updating post: ", error);
    }
  };

  // Function to approve the post
  const approvePost = async () => {
    const postRef = doc(db, "posts", post.id);
    try {
      await updateDoc(postRef, {
        approved: true, // Set the post as approved
      });

        // Notify the user
    await sendNotification(post.userId, `帖子【${title}】通过审核.`, false);
      onUpdate(); // Refresh the posts list
      console.log("Post approved successfully");
    } catch (error) {
      console.error("Error approving post: ", error);
    }
  };

  // Function to reject the post with a reason
  const rejectPost = async () => {
    const postRef = doc(db, "posts", post.id);
    try {
      await updateDoc(postRef, {
        approved: false,
        rejected: true,
        rejectionReason: rejectionReason, // Store the rejection reason
      });

      // Notify the user
      await sendNotification(post.userId, `帖子【${title}】被拒: ${rejectionReason}； 请查看【我的帖子】`, true);

      onUpdate(); // Refresh the posts list
      console.log("Post rejected successfully");
    } catch (error) {
      console.error("Error rejecting post: ", error);
    }
  };

  // Function to send notification to the user
  const sendNotification = async (userId: string, message: string, rejected: boolean) => {
    const notificationsRef = collection(db, "notifications");
    await addDoc(notificationsRef, {
      userId: userId,
      message: message,
      unread: true, 
      timestamp: new Date(),
      rejected: rejected,
    });
  };

  // Sanitize the HTML content
  const sanitizedDescription = DOMPurify.sanitize(description);

  return (

    <div className="individual-post">
        { isEditing ? (
            <>          
                <div className="edit-wrapper">
            
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Title"
                        className="edit-input"
                    />
                    {/* <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description"
                        className="edit-textarea"
                    /> */}
                             {/* Use ReactQuill instead of textarea */}
                    <ReactQuill
                      value={description}
                      onChange={setDescription}
                      modules={{
                        toolbar: [
                          [{ size: ["small", false, "large"] }],
                          ["bold", "italic", "underline"],
                          [{ list: "ordered" }, { list: "bullet" }],
                          ["link"],
                        ],
                      }}
                      theme="snow"
                      placeholder="Description"
                      className="edit-quill" // Optional class for custom styling
                    />
                    <select value={category} onChange={(e) => {
                         console.log("Selected value:", e.target.value); 
                        setCategory(e.target.value);                        
                        }}>
                        
                        {/* <option value="sell">选择主题</option> */}
                        <option value="sell">出售</option>
                        <option value="buy">求购/求助</option>
                        <option value="for-rent">出租/招租</option>
                        <option value="to-rent">求租</option>

                    </select>

                    <div>
                        <button onClick={saveChanges} className="save-btn">Save</button>
                        <button onClick={() => setIsEditing(false)} className="cancel-btn">Cancel</button>
                    </div>
                </div>
            </>
                ) : (

            <>
                <div className="post-wrapper">
                    <h2>{title}</h2>
                    <p>Category: {category}</p>
                    <p>Author: {post.username}</p>
                    {/* <p>{description}</p> */}
                    <div dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
                    {Array.isArray(post.imageUrls) && post.imageUrls.length > 0 && (
                        <div className="post-images">
                            {post.imageUrls.map((url, index) => (
                                <img key={index} src={url} alt={`Post Image ${index}`} style={{ width: "200px", margin: "5px" }} />
                            ))}
                        </div>
                    )}
            

                {/* Approve Button */}
                    <div>
                        <button onClick={approvePost} className="approve-btn">Approve</button>
                    </div>

                    <div>
                        <button onClick={() => setIsEditing(true)} className="edit-btn">Edit</button>
                    </div>
                {/* Rejection Section */}
                    <div>
                        <input
                            value={rejectionReason}
                            onChange={(e) => setRejectionReason(e.target.value)}
                            placeholder="Reason for rejection"
                            className="reject-text"
                        />
                    </div>

                    <button onClick={rejectPost} className="reject-btn">Reject</button>
                
                </div>
            </>
            )
        }
    </div>
  );
};

