import React, { useEffect, useState } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../config/firebase.ts"; // Adjust the import based on your structure
import { useParams, Link } from "react-router-dom";
import "./posts_practice.css"

interface Post {
  id: string;
  title: string;
  description: string;
  category: string;
  username: string | undefined;
  imageUrls: string[]; // Ensure this is an array
}

export const PostPractice = () => {
  const { category } = useParams<{ category: string }>();

  const [posts, setPosts] = useState<Post[]>([]);
  
  const postsRef = collection(db, "posts");

  useEffect(() => {
    const unsubscribe = onSnapshot(postsRef, (snapshot) => {
      const fetchedPosts: Post[] = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Post[];
      setPosts(fetchedPosts);
    });
    return () => unsubscribe();
  }, []);

  const filteredPosts = category 
    ? posts.filter((post) => post.category === category)
    : posts;

  return (
    <div className="posts-container">
      <h1>所有帖子</h1>

      <div className="category-filter">
        <Link to="/category/sell">出售</Link>
        <Link to="/category/buy">求购/求助</Link>
        <Link to="/category/for-rent">出租/招租</Link>
        <Link to="/category/to-rent">求租</Link>
      </div>

      {filteredPosts.length > 0 ? (
        filteredPosts.map((post) => (
          <div key={post.id} className="post">

            <h2>
                <Link to={`/category/${post.category}/${post.id}`}>
                    {post.title}
                </Link>
                     
            </h2>


          </div>
        ))
      ) : (
        <p>没有帖子可显示</p>
      )}
    </div>
  );
};
