import React, { useEffect, useState } from "react";
import { collection, query, where, getDocs, updateDoc, doc, deleteDoc } from "firebase/firestore";
import { db } from "../config/firebase.ts"; // Adjust the import based on your structure
import "./deletedposts.css";
import DOMPurify from 'dompurify'; // Import DOMPurify
import { Link } from "react-router-dom";
import logo from '../images/AZ LIFE2.png'; 
import { getStorage, ref, deleteObject } from "firebase/storage";

// Define the Post interface
interface Post {
  id: string; // Add other properties as needed
  title: string;
  category: string;
  username: string;
  description: string;
  imageUrls: string[]; // Include image URLs in the Post interface
}

export const DeletedPosts: React.FC = () => {
  const [deletedPosts, setDeletedPosts] = useState<Post[]>([]); // Define state with Post type

  useEffect(() => {
    const fetchDeletedPosts = async () => {
      const q = query(collection(db, "posts"), where("deleted", "==", true));
      const querySnapshot = await getDocs(q);
      const posts: Post[] = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Post));
      setDeletedPosts(posts);
    };

    fetchDeletedPosts();
  }, []);

  const restorePost = async (postId: string) => {
    const postRef = doc(db, "posts", postId);
    try {
      await updateDoc(postRef, {
        deleted: false,
        deletedAt: null, // Optionally clear the deletedAt field
      });
      setDeletedPosts(deletedPosts.filter(post => post.id !== postId)); // Remove from state
      console.log("Post restored successfully");
    } catch (error) {
      console.error("Error restoring post:", error);
    }
  };

  const permanentlyDeletePost = async (postId: string, imageUrls: string[]) => {
    const postRef = doc(db, "posts", postId);
    try {

      const storage = getStorage();
      const deleteImagePromises = imageUrls.map((url) => {
        const imageRef = ref(storage, url);
        return deleteObject(imageRef).catch((error) => {
          console.error("Error deleting image:", error);
        });
      });
      await Promise.all(deleteImagePromises);
      await deleteDoc(postRef); // Permanently delete the document
      setDeletedPosts(deletedPosts.filter(post => post.id !== postId)); // Remove from state
      console.log("Post permanently deleted successfully");
    } catch (error) {
      console.error("Error permanently deleting post:", error);
    }
  };

  return (
    <div className="deleted-posts">
       <Link to="/" >
                <img src={logo} width="150px" alt="Logo" className="deleted-post-logo" />
        </Link>

      <div className="title_t">Deleted Posts</div>

      <div className="post-ul">
            {/* <ul className="post-ul"> */}
              {deletedPosts.map(post => (
                <div key={post.id} className="post-info">
                  <h1 className="title-post">{post.title}</h1>
                  <p className="info">Category: {post.category}</p>
                  <p className="info">Author: {post.username}</p>

                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.description) }} className="deleted-post-desccription"/>

                  {/* Render images associated with the post */}
                  {post.imageUrls && post.imageUrls.length > 0 && (
                    <div className="post-images">
                      {post.imageUrls.map((url, index) => (
                        <img key={index} src={url} alt={`Post Image ${index}`} style={{ width: "150px", margin: "5px" }} />
                      ))}
                    </div>
                  )}

                  <button onClick={() => restorePost(post.id)}>Restore</button>
                  <button onClick={() => permanentlyDeletePost(post.id, post.imageUrls)}>Delete Permanently</button>
                </div>
              ))}
            {/* </ul> */}
        </div>
    </div>
  );
};
