import React, { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Link, useLocation } from "react-router-dom";
import { db } from "../config/firebase.ts"; 

import { Navibar } from "../components/Navibar.tsx";
// import "./category_posts.css"; 
import "./searchresults.css"; 
import { CategoryMap } from './categorymap.tsx';
import { faEye, faHeart, faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';






interface Post {
  id: string;
  title: string;
  description: string;
  category: string;
  views: number;
  collections: number;
  createdAt: { seconds: number; nanoseconds: number };
  keywords: string[];
}

export const SearchResults = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const location = useLocation();
  const searchTerm = location.state?.searchTerm || "";

  useEffect(() => {
    const fetchPosts = async () => {
      if (searchTerm) {
        const postsRef = collection(db, "posts");

         // Retrieve all posts first
        const querySnapshot = await getDocs(postsRef);
        const fetchedPosts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Post[];

      const normalizedSearchTerm = searchTerm.trim(); // Trim whitespace

      // Check if the search term is likely in Chinese
    const isChinese = /^[\u4e00-\u9fa5]+$/.test(normalizedSearchTerm);
    console.log('isChinese', isChinese)

    let filteredPosts;

    if (isChinese) {
      // Handle Chinese search logic
      const searchChars = normalizedSearchTerm.split(''); // Split into characters
      
      filteredPosts = fetchedPosts.filter(post => {
          // Ensure post.keywords exists and is an array
          if (post.keywords && Array.isArray(post.keywords)) {
              // Check if all characters in searchChars exist in post.keywords
              return searchChars.every(char => post.keywords.includes(char));
          }
          return false; // If post.keywords doesn't exist or is not an array, exclude the post
      });
  } else {
      // Handle English search logic
      filteredPosts = fetchedPosts.filter(post => 
          post.title && post.title.toLowerCase().includes(normalizedSearchTerm.toLowerCase())
      );
  }

    // Sort posts by created time in descending order (latest first)
    const sortedPosts = filteredPosts.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

    setPosts(sortedPosts);
  }
};

    

        // Firestore query to find posts where title contains the search term (case-insensitive)
        // const q = query(
        //   postsRef,
        //   where("title", ">=", normalizedSearchTerm),
        //   where("title", "<=", normalizedSearchTerm + "\uf8ff")
        // );

      
        // Sort posts by created time in descending order (latest first)
    //     const sortedPosts = fetchedPosts.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

    //     setPosts(sortedPosts);

    //     // setPosts(fetchedPosts);
    //   }
    // };

    fetchPosts();
  }, [searchTerm]);

  // Group posts by category
  const groupedPosts = posts.reduce((group: { [key: string]: Post[] }, post: Post) => {
    const category = post.category || "Uncategorized";
    if (!group[category]) {
      group[category] = [];
    }
    group[category].push(post);
    return group;
  }, {});

  return (
    <div className="search-posts">
      <div className="collection-header">
        <Navibar />
      </div>

      <div className="collection-container2">
        <h3>搜索结果: {searchTerm}</h3>

        {Object.keys(groupedPosts).length > 0 ? (
          Object.keys(groupedPosts).map((category) => (
            <div key={category} className="category-section">

              
              {/* <h3 className="header-category">{category.charAt(0).toUpperCase() + category.slice(1)}</h3> */}
              <h3 className="header-category">
                    <Link to={`/category/${category}`}>
                          {CategoryMap[category]}
                    </Link>
                
              </h3>
              <table className="posts-table">
                <thead>
                  <tr>
                    <th className="title-column">标题</th>
                    {/* <th className="views-column">浏览量</th>
                    <th className="collections-column">收藏数</th>
                    <th className="created-column">发帖时间</th> */}
                <th className="views-column"><FontAwesomeIcon icon={faEye} size="sm"/></th>
                <th className="collections-column"><FontAwesomeIcon icon={faHeart} style={{ color: 'red' }} size="sm"/> </th>
                <th className="created-column"><FontAwesomeIcon icon={faCalendarAlt} size="sm" /></th>
                  </tr>
                </thead>
                <tbody>
                  {groupedPosts[category].map((post) => (
                    <tr key={post.id}>
                      <td className="title-column">
                        <Link to={`/category/${post.category}/${post.id}`} className="post-link">
                          {post.title}
                        </Link>
                      </td>
                      <td className="views-column">{post.views || 0}</td>
                      <td className="collections-column">{post.collections || 0}</td>
                      <td className="created-column">
                        {new Date(post.createdAt.seconds * 1000).toLocaleDateString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))
        ) : (
          <p className='no-search-posts'>没有结果.</p>
        )}
      </div>
    </div>
  );
};
