// import { useState } from 'react';
// import { createUserWithEmailAndPassword, sendEmailVerification, signOut, updateProfile } from 'firebase/auth';
// import { auth, db } from "../config/firebase.ts";
// import { Link } from 'react-router-dom';
// import React from 'react';
// import logo from '../images/AZ LIFE2.png';
// import "./signup.css";
// import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
// import { useNavigate } from 'react-router-dom';




// export const SignUp = () => {
//   const [email, setEmail] = useState("");
//   const [username, setUsername] = useState("");
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
//   const [error, setError] = useState<string | null>(null);
//   const navigate = useNavigate();
 

//   const handleSignUp = async (e) => {
    
    
//     e.preventDefault();
//     if (password !== confirmPassword) {
//       setError("Passwords do not match!");
//       return;
//     }

//     setError(null);

//     try {
//       // Check if username already exists
//       const usersRef = collection(db, "users");
//       const usernameQuery = query(usersRef, where("username", "==", username));
//       const querySnapshot = await getDocs(usernameQuery);

//       if (!querySnapshot.empty) {
//         setError("Username already exists. Please choose another.");
//         return;
//       }

//       // Create user with email and password
//       const userCredential = await createUserWithEmailAndPassword(auth, email, password);

  
//       // Set the username as the user's displayName
//       await updateProfile(userCredential.user, {
//         displayName: username,
//       });

//        // Send a verification email
//        await sendEmailVerification(userCredential.user);

//        // Immediately sign the user out after creating the account
//        await signOut(auth);


//         // Send a verification email
//         await sendEmailVerification(userCredential.user);
//         alert("验证邮件已发送（有可能会被标记为垃圾邮件）。");
        

//       // Store user data in Firestore
//       await setDoc(doc(db, "users", userCredential.user.uid), {
//         username: username,
//         email: email,
//         createdAt: new Date(),
//       });


//       // Store the email and password in localStorage for future login
//       // localStorage.setItem("email", email);
//     //   localStorage.setItem("password", password);
//       navigate("/signinwithemail");

//       // You can add a success message here if needed, but no redirection.
//     } catch (error) {
//       setError(error.message);
//     }
//   };

//   return (
//     <div className="signup-container">
//       <div className='signup'>
//         <form onSubmit={handleSignUp} className="signup-form">
//           <h2>注册新账户</h2>
//           {error && <p style={{ color: "red" }}>{error}</p>}

//           {/* Username Field */}
//           <div className="signup_username">Username:</div>
//           <input
//             type="text"
//             value={username}
//             onChange={(e) => setUsername(e.target.value)}
//             required
//             className="signupusername"
//           />

//           {/* Email Field */}
//           <div className="signup_email">Email: </div>
//           <input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//             className="signupemail"
//           />

//           {/* Password Field */}
//           <div className="signup_password">Password:</div>
//           <input
//             type={showPassword ? "text" : "password"}
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             required
//             className="signuppassword"
//           />

//           {/* Confirm Password Field */}
//           <div className="confirm_password">Confirm Password:</div>
//           <input
//             type={showPassword ? "text" : "password"}
//             value={confirmPassword}
//             onChange={(e) => setConfirmPassword(e.target.value)}
//             required
//             className="confirmpassword"
//           />

//           {/* Show Password Checkbox */}
//           <div className='showpassword-box'>
//             <input
//               type="checkbox"
//               checked={showPassword}
//               onChange={() => setShowPassword(!showPassword)}
//               className="showpassword-checkbox"
//               id="myCheckbox"
//             />
//             <label className="showpassword-label" htmlFor="myCheckbox">Show Password</label>
//           </div>

//           {/* Register Button */}
//           <div>
//             <button type="submit" className="register">Register</button>
            
//           </div>
//         </form>
//       </div>

//       <div className="signin_part">
//         <div>已经有账户？</div>
//         <Link to='/signinwithemail' className="signin-account">
//           登录
//         </Link>
//       </div>
//     </div>
//   );
// };


import { useState } from 'react';
import { createUserWithEmailAndPassword, sendEmailVerification, signOut, updateProfile } from 'firebase/auth';
import { auth, db } from "../config/firebase.ts";
import { Link } from 'react-router-dom';
import React from 'react';
import logo from '../images/AZ LIFE2.png';
import "./signup.css";
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

export const SignUp = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
 
  const handleSignUp = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match!");
      return;
    }

    setError(null);

    try {
      // Normalize username to lowercase for case-insensitive checks
      const normalizedUsername = username.trim().toLowerCase();

      // Check if username already exists
      const usersRef = collection(db, "users");
      const usernameQuery = query(usersRef, where("username", "==", normalizedUsername));
      const querySnapshot = await getDocs(usernameQuery);

      if (!querySnapshot.empty) {
        setError("Username already exists. Please choose another.");
        return;
      }

      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);

      // Set the username as the user's displayName
      await updateProfile(userCredential.user, {
        displayName: normalizedUsername,
      });

      // Store user data in Firestore
    await setDoc(doc(db, "users", userCredential.user.uid), {
      username: normalizedUsername,
      email: email,
      createdAt: new Date(),
    });

      // Send a verification email
      await sendEmailVerification(userCredential.user);
      alert("验证邮件已发送 (可能在spam folder).");

      // Immediately sign the user out after creating the account
      await signOut(auth);

     

      // Store user data in Firestore
      // await setDoc(doc(db, "users", userCredential.user.uid), {
      //   username: normalizedUsername,
      //   email: email,
      //   createdAt: new Date(),
      // });

      navigate("/signinwithemail");

    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="signup-container">
      <div className='signup'>
        <form onSubmit={handleSignUp} className="signup-form">
          <h2>注册新账户</h2>
          {error && <p style={{ color: "red" }}>{error}</p>}

          {/* Username Field */}
          <div className="signup_username">Username:</div>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="signupusername"
          />

          {/* Email Field */}
          <div className="signup_email">Email: </div>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="signupemail"
          />

          {/* Password Field */}
          <div className="signup_password">Password:</div>
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="signuppassword"
          />

          {/* Confirm Password Field */}
          <div className="confirm_password">Confirm Password:</div>
          <input
            type={showPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className="confirmpassword"
          />

          {/* Show Password Checkbox */}
          <div className='showpassword-box'>
            <input
              type="checkbox"
              checked={showPassword}
              onChange={() => setShowPassword(!showPassword)}
              className="showpassword-checkbox"
              id="myCheckbox"
            />
            <label className="showpassword-label" htmlFor="myCheckbox">Show Password</label>
          </div>

          {/* Register Button */}
          <div>
            <button type="submit" className="register">Register</button>
          </div>
        </form>
      </div>

      <div className="signin_part">
        <div>已经有账户？</div>
        <Link to='/signinwithemail' className="signin-account">
          登录
        </Link>
      </div>
    </div>
  );
};
