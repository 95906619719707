import { doc, getDoc } from "firebase/firestore";
import { db } from "../config/firebase.ts";

interface BlockedUserData {
    blocked: boolean;
    reason: string;
    blockedAt: Date; // Or Firebase Timestamp type if you're using that
  }
  

  export const checkIfUserIsBlocked = async (userId: string): Promise<boolean> => {
    const blockedUserRef = doc(db, "blockedUsers", userId);
    const docSnapshot = await getDoc(blockedUserRef);
  
    if (docSnapshot.exists()) {
      const userData = docSnapshot.data() as BlockedUserData; // Cast to BlockedUserData
      if (userData) {
        console.log("User is blocked.");
        return true; // User is blocked
      }
    }
    return false; // User is not blocked
  };