import { addDoc, collection, Timestamp } from "firebase/firestore";
import { auth, db } from "../config/firebase.ts";
import React, { useState } from "react";
import "./comments_form.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { useBlockedUser } from "../admin/blockedusercontext.tsx";

export const AddComment = ({ postuserId, postId, category }) => {
    const [comment, setComment] = useState("");
    const [user] = useAuthState(auth);  // Hook to get the logged-in user
    const { isBlocked } = useBlockedUser();
    
    // Function to send notification to the user
    const sendNotification = async (message: string, postlink: string) => {
        console.log("Notification is being sent:", message, postlink);
        const notificationsRef = collection(db, "notifications");
        await addDoc(notificationsRef, {
        userId: postuserId,
        message: message,
        link: postlink,
        unread: true, 
        timestamp: new Date(),
        rejected: false,
        });
    };

    
    const handleAddComment = async () => {
      if (!comment.trim()) {
        alert("Comment cannot be empty");
        return;
      }
  
      if (!user) {
        alert("You need to be logged in to comment.");
        return;
      }
      
  
      try {
        await addDoc(collection(db, "comments"), {
          postId: postId,
          category: category,
          comment: comment,
          username: user.displayName || "Anonymous",  // Fetch username from user object
          userId: user.uid,  // Store the user's unique ID
          createdAt: Timestamp.now(),
        });

           // Send notification to the post owner (if commenter is not the post owner)
        if (user.uid !== postuserId) {
            console.log("userId is not post userId")
            const postLink = `/category/${category}/${postId}`;  // Link to the post
            await sendNotification(`${user.displayName || "Anonymous"} commented on your post.`, postLink);
        }

  
        setComment("");  // Clear the input field after successful submission
      } catch (error) {
        console.error("Error adding comment: ", error);
      }
    };



  
    return (
      <div className="comment_input">
        <h2>留言</h2>
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Add your comment..."
          className="comment-textarea"
        />
        <div>
          <button onClick={handleAddComment} disabled={isBlocked} className="comment-submit-btn">
           {isBlocked && "你的账号被封，请联系管理员."}
            评论
          </button>
        </div>
      </div>
    );
  };
